/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable curly */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */

import {
  ExtraOptions,
  PreloadAllModules,
  Route,
  RouterModule,
  Routes,
} from '@angular/router';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { HomeComponent } from './components/pages/home/home.component';
import { OurServicesComponent } from './components/pages/our-services/our-services.component';
import { SimplePageComponent } from './components/pages/simple-page/simple-page.component';

export const appRoutes: Routes = [

  {
    path: 'qui-sommes-nous',
    component: AboutUsComponent,
  },
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'nos-services-et-prestations',
    component: OurServicesComponent,
  },
  {
    path: 'simple-page',
    component: SimplePageComponent,
  },
  {
    path: 'blog',
    loadChildren: () => import('src/app/components/blog/blog.module').then(m => m.BlogModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('src/app/components/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '**',
    component: ErrorComponent
  }
];


const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload'
};
const routerModule = RouterModule.forRoot(appRoutes, routerConfig);

@NgModule({
  imports: [routerModule],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
