import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ArticlesListComponent } from './articles-list/articles-list.component';
import { RouterModule } from '@angular/router';
import { PipeModule } from '../../pipes/pipe.module';
import { DirectiveModule } from '../../directives/directive.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SearchComponent } from './search/search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './alert/alert.component';


@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    ArticlesListComponent,
    SidebarComponent,
    SearchComponent,
    AlertComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    PipeModule,
    DirectiveModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    ArticlesListComponent,
    CommonModule, RouterModule, PipeModule, DirectiveModule, SidebarComponent,
    SearchComponent,
    AlertComponent
  ]
})
export class SharedModule {

}
