import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '../../../components/shared/animations';
import { BdService } from '../../../services/bd.service';
import { UserSession } from '../../../services/user-session.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  animations: [fuseAnimations],
  styleUrls: ['./our-services.component.css']
})
export class OurServicesComponent implements OnInit {
  nos_services_type: any;
  nos_services_elements: any;
  qui_sommes_nous_type: any;
  qui_sommes_nous_menu: any;
  nos_services_menu: any;
  acceuil_menu: any;


  constructor(public userSession: UserSession,
    public route: ActivatedRoute,
    public utils_service: UtilsService,
    public bd_service: BdService) { }

  ngOnInit(): void {
    //trigger get data to actuialise th data in background
    //this.bd_service.get_data();
    //but continue with availabe data
    if (this.bd_service.public_types_elements.length == 0 || this.bd_service.public_elements.length == 0) {
      this.bd_service.public_observable.subscribe(data => {
        //console.log('data updated !');
        this.prepare_data();
      });
    } else {
      this.prepare_data();
    }

  }

  prepare_data() {

    //service une phrase
    this.nos_services_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_PRODUITS_SERVICES, this.bd_service.public_types_elements);
    this.nos_services_elements = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.SECTION_PRODUITS_SERVICES, this.bd_service.public_elements);
    switch (this.nos_services_type.contenu.our_products_order_by) {
      case "Plus récents d'abord":
        this.nos_services_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        break;
      case "Plus anciens d'abord":
        this.nos_services_elements.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        break;
      case "Priorité personnalisée":
        this.nos_services_elements.sort((a, b) => a.contenu.our_products_element_priority - b.contenu.our_products_element_priority);
        break;

      default:
        this.nos_services_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        break;
    }

    const menus = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.ELEMENTS_DU_MENU, this.bd_service.public_elements);
    this.nos_services_menu = menus.find(m => m.contenu?.menu_item_element_page_link_link_obj?.slug == "nos-services-et-prestations");
    this.acceuil_menu = menus.find(m => m.contenu?.menu_item_element_page_link_link_obj?.slug == "acceuil");
    //console.log('nos_services_menu', this.nos_services_menu);


  }


}
