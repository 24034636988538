/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable curly */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable arrow-parens */


import { AfterViewInit, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Elements, TypeElements } from '../../../interfaces';
import { BdService } from '../../../services/bd.service';
import { UserSession } from '../../../services/user-session.service';
import { UtilsService } from '../../../services/utils.service';
declare var $: any
import { fuseAnimations } from '../../../components/shared/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  animations: [fuseAnimations],
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  profil_links = [];
  carousel_elements: Elements[] = [];
  @ViewChildren('heroCarousel') carousel: QueryList<any>;
  heroCarouselPopulated: any;
  autoplayTimeout = 2000;
  service_une_phrase_type: TypeElements;
  service_une_phrase_elements: Elements[] = [];
  qui_sommes_nous_type: TypeElements;
  stats_type: TypeElements;
  stats_elements: Elements[] = [];
  pourquoi_nous_choisir_type: TypeElements;
  services_type: TypeElements;
  services_elements: Elements[] = [];
  acceuil_blog_type: TypeElements;
  testimonials_type: TypeElements;
  testimonials_elements: Elements[] = [];
  maxArticles = 6;
  statsCounterDelay = 2;
  statPopulated: boolean;
  servicesCarouselPopulated: any;
  articles: any[];
  articlesPopulated: any;
  testimonialsPopulated: boolean;

  @ViewChild('videoSidebar')
  set watch(videoSidebar: ElementRef) {
    if (videoSidebar) {
      this.initVideoPopup();
    }
  }

  constructor(public userSession: UserSession,
    public route: ActivatedRoute,
    public utils_service: UtilsService,
    public bd_service: BdService) { }

  ngOnInit(): void {
    //trigger get data to actuialise th data in background
    //this.bd_service.get_data();
    //but continue with availabe data
    if (this.bd_service.public_types_elements.length == 0 || this.bd_service.public_elements.length == 0) {
      this.bd_service.public_observable.subscribe(data => {
        //console.log('data updated !');
        this.prepare_data();
      });
    } else {
      this.prepare_data();
    }

  }

  prepare_data() {
    //carousel
    if (!this.heroCarouselPopulated) {
      this.carousel_elements = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.ACCUEIL_CAROUSSEL, this.bd_service.public_elements);
      const autoplayTimeout = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.ACCUEIL_CAROUSSEL, this.bd_service.public_types_elements)?.contenu?.slide_delay;
      if (autoplayTimeout != undefined && autoplayTimeout != null && autoplayTimeout != '') this.autoplayTimeout = autoplayTimeout * 1000;
      setTimeout(() => {
        this.initHeroCarousel();
      }, 300);
    }

    //service une phrase
    this.service_une_phrase_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_SERVICES_EN_UNE_PHRASE, this.bd_service.public_types_elements);
    this.service_une_phrase_elements = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.SECTION_SERVICES_EN_UNE_PHRASE, this.bd_service.public_elements);
    switch (this.service_une_phrase_type.contenu.one_phrase_service_order_by) {
      case "Plus récents d'abord":
        this.service_une_phrase_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        break;
      case "Plus anciens d'abord":
        this.service_une_phrase_elements.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        break;
      case "Priorité personnalisée":
        this.service_une_phrase_elements.sort((a, b) => a.contenu.one_phrase_service_element_priority - b.contenu.one_phrase_service_element_priority);
        break;

      default:
        this.service_une_phrase_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        break;
    }

    //qui sommes nous
    this.qui_sommes_nous_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_QUI_SOMMES_NOUS, this.bd_service.public_types_elements);
    //console.log('qui_sommes_nous_type', this.qui_sommes_nous_type);


    //stats
    if (!this.statPopulated) {
      this.stats_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_STATISTIQUES_COMPTEURS, this.bd_service.public_types_elements);
      const statsCounterDelay = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_STATISTIQUES_COMPTEURS, this.bd_service.public_types_elements)?.contenu?.stat_counter_duration;
      if (statsCounterDelay != undefined && statsCounterDelay != null && statsCounterDelay != '') this.statsCounterDelay = statsCounterDelay * 1000;
      this.stats_elements = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.SECTION_STATISTIQUES_COMPTEURS, this.bd_service.public_elements);
      switch (this.stats_type.contenu.stat_counter_order_by) {
        case "Plus récents d'abord":
          this.stats_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
        case "Plus anciens d'abord":
          this.stats_elements.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
          break;
        case "Priorité personnalisée":
          this.stats_elements.sort((a, b) => a.contenu.stat_counter_element_priority - b.contenu.stat_counter_element_priority);
          break;

        default:
          this.stats_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
      }
    }

    //pourquoi nous choisir
    this.pourquoi_nous_choisir_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.ACCUEIL_SECTION_POURQUOI_NOUS_CHOISIR, this.bd_service.public_types_elements);

    //section services
    if (!this.servicesCarouselPopulated) {
      this.services_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_PRODUITS_SERVICES, this.bd_service.public_types_elements);
      this.services_elements = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.SECTION_PRODUITS_SERVICES, this.bd_service.public_elements);
      switch (this.services_type.contenu.our_products_order_by) {
        case "Plus récents d'abord":
          this.services_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
        case "Plus anciens d'abord":
          this.services_elements.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
          break;
        case "Priorité personnalisée":
          this.services_elements.sort((a, b) => a.contenu.our_products_element_priority - b.contenu.our_products_element_priority);
          break;

        default:
          this.services_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
      }
    }

    //console.log('services_elements', this.services_elements);

    //blog
    if (!this.articlesPopulated) {
      console.log('!this.articlesPopulated', !this.articlesPopulated);
      
      this.acceuil_blog_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.ACCUEIL_SECTION_BLOG, this.bd_service.public_types_elements);
      const maxArticles = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.ACCUEIL_SECTION_BLOG, this.bd_service.public_types_elements)?.contenu?.home_blog_item_count;
      if (maxArticles != undefined && maxArticles != null && maxArticles != '') this.maxArticles = +maxArticles;
      //get articles
      /* fetch('https://api.geoiplookup.net/?json=true')
      .then(response => response.json())
      .then(data => {
        this.bd_service.getArrayData(
          this.bd_service.get_published_articles({per_page: 50}, [data.countrycode]),
          (data_array: any[], message: any,) => {
          });
        })
        .catch(error => {
          console.error('Error fetching location data:', error);
        }); */
        this.articles = [];

        switch (this.acceuil_blog_type.contenu.home_blog_order_by) {
          case "Plus récents d'abord":
            this.articles = this.bd_service.public_articles.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).slice(0, this.maxArticles);
            break;
          case "Plus anciens d'abord":
            this.articles = this.bd_service.public_articles.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()).slice(0, this.maxArticles);
            break;

          default:
            this.articles = this.bd_service.public_articles.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).slice(0, this.maxArticles);
            break;
        }
        this.articlesPopulated = true;
      

    }


    //temoignages
    if (!this.testimonialsPopulated) {

      this.testimonials_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.ACCUEIL_SECTION_AVIS_TEMOIGNAGES, this.bd_service.public_types_elements);
      this.testimonials_elements = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.ACCUEIL_SECTION_AVIS_TEMOIGNAGES, this.bd_service.public_elements);
      switch (this.testimonials_type.contenu.home_testimonials_order_by) {
        case "Plus récents d'abord":
          this.testimonials_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
        case "Plus anciens d'abord":
          this.testimonials_elements.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
          break;
        case "Priorité personnalisée":
          this.testimonials_elements.sort((a, b) => a.contenu.home_testimonials_element_priority - b.contenu.home_testimonials_element_priority);
          break;

        default:
          this.testimonials_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
      }
    }


  }


  initVideoPopup() {
    $(".video-placeholder").magnificPopup({
      disableOn: 700,
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });
  }

  @Input()
  set carousel_ready(isReady: boolean) {
    console.log('carousel_ready');

    if (isReady) this.initHeroCarousel();
  }

  ngAfterViewInit(): void {
    const self = this;

    /* initStatCounters */
    $(".stats-digits").on("scrollSpy:enter", function () {
      $(".countfect").each(function () {
        var $this = $(this);
        var countTo = $this.attr("data-num");
        var delayTo = +$this.attr("delay");
        if (!delayTo) {
          delayTo = 8000;
        }
        $({
          countNum: $this.text(),
        }).animate(
          {
            countNum: countTo,
          },
          {
            duration: delayTo,
            easing: "linear",
            step: function (this) {
              $this.text(Math.floor(+this.countNum));
            },
            complete: function () {
              $this.text(this.countNum);
            },
          }
        );
      });
    });

    $(".stats-digits").scrollSpy();

    /* initVideoPopup */
    $(".video-placeholder").magnificPopup({
      disableOn: 700,
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });


    /* initTestimonialCarroussel */
    var owl = $(".owl-carousel");
    owl.owlCarousel({
      autoPlay: 100,
      nav: true,
      items: 1,
    });

    $(".owl-carousel .testi-profil").each(function (index, element) {
      // element == this
      var link = $(this).attr("src");
      var anim_index = 1;
      if (index == 0 || index == 3 || index == 6 || index == 9) {
        anim_index = 1;
      }
      if (index == 1 || index == 4 || index == 7 || index == 10) {
        anim_index = 2;
      }
      if (index == 2 || index == 5 || index == 8 || index == 11) {
        anim_index = 3;
      }

      self.profil_links.push({
        link: link,
        id: index,
      });
      setTimeout(() => {
        $(".carousel-container .floating-profiles").append(
          '<img src="' +
          link +
          '" id="fl_img_' +
          index +
          '" position="' +
          index +
          '" class="floating-profiles-img particlemove' +
          anim_index +
          '" alt=""></img>'
        );
      }, 500);
    });
    //console.log(self.profil_links);

    $(document).on("click", ".floating-profiles-img", function (event) {
      var id = event.target.id;
      var position = $("#" + id).attr("position");
      console.log(position);
      owl.trigger("to.owl.carousel", position);
    });

    $("").click(function (e) {
      e.preventDefault();
    });


    //console.log('carousel', carousel);
  }

  initStatCount() {
    if (!this.statPopulated) {
      setTimeout(() => {

        $(".stats-digits").on("scrollSpy:enter", function () {
          //console.log('to init stat count');
          $(".countfect").each(function () {
            var $this = $(this);
            var countTo = $this.attr("data-num");
            var delayTo = +$this.attr("delay");
            if (!delayTo) {
              delayTo = 8000;
            }
            $({
              countNum: $this.text(),
            }).animate(
              {
                countNum: countTo,
              },
              {
                duration: delayTo,
                easing: "linear",
                step: function () {
                  $this.text(Math.floor(this.countNum));
                },
                complete: function () {
                  $this.text(this.countNum);
                },
              }
            );
          });
        });
        $(".stats-digits").scrollSpy();
        this.statPopulated = true;
      }, 300);
    }
  }

  setTestimonialsPopulated() {
    this.testimonialsPopulated = true;
  }

  initServicesCarousel() {
    if (!this.servicesCarouselPopulated) {
      //recover the carousel class
      setTimeout(() => {
        $(".produits-carousel-container")
          .children(".will-be-owl-carousel")
          .addClass("owl-carousel");
        $(".produits-carousel-container")
          .children(".owl-carousel")
          .removeClass("will-be-owl-carousel");
        var owl = $(".produits-carousel-container").children(".owl-carousel");
        owl.owlCarousel({
          autoplay: true,
          nav: true,
          items: 4,
          dots: true,
          loop: true,
          center: true,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 1,
            },
            1000: {
              items: 3,
            },
            1400: {
              items: 4,
            },
          },
        });
      }, 300);
      this.servicesCarouselPopulated = true;
    }
  }

  initHeroCarousel() {
    /* initHeroCarousel */
    //recover the carousel class
    if (!this.heroCarouselPopulated) {
      setTimeout(() => {
        $(".hero-carousel-container")
          .children(".will-be-owl-carousel")
          .addClass("owl-carousel");
        $(".hero-carousel-container")
          .children(".owl-carousel")
          .removeClass("will-be-owl-carousel");
        var owl = $(".hero-carousel-container").children(".owl-carousel").owlCarousel({
          autoplay: true,
          nav: true,
          items: 1,
          dots: true,
          loop: true,
          center: true,
          autoplayTimeout: this.autoplayTimeout
        });
        //console.log('owl', owl);
      }, 300);
      this.heroCarouselPopulated = true;
    }

  }

}
