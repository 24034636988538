import { Directive, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Elements, TypeElements } from '../interfaces';

@Directive({
  selector: '[appRouterOrHref]'
})
export class RouterOrHrefDirective {
  el: HTMLElement;

  constructor(elRef: ElementRef, public router: Router) {
    this.el = elRef.nativeElement;
  }

  @Input() set appRouterOrHref(data: {element: Elements | TypeElements, field_key: string}) {
    const self = this;
    const link_obj = data.element?.contenu[data.field_key + '_link_obj'];
    //console.log('initialUrl', this.router.url);
    if(link_obj?.slug == 'custom_link'){
      this.el.setAttribute("href", data.element?.contenu[data.field_key]);
    }else{
      if(this.router.url == '/' + link_obj?.route){
        this.el.classList.add("active");
      }else{
        this.el.classList.remove("active");
      }
      this.el.setAttribute("href", '/' + link_obj?.route);
      this.el.setAttribute("gen-router-link", '/' + link_obj?.route);
      this.el.addEventListener('click', function(e) {
        e.preventDefault();
        self.router.navigateByUrl('/' + link_obj?.route);
      }, false);
    }
  }

}
