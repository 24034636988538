/* eslint-disable @typescript-eslint/naming-convention */
import { Pipe, PipeTransform } from '@angular/core';
import { BdService } from '../services/bd.service';

@Pipe({
  name: 'imgUrl',
})
export class ImgUrlPipe implements PipeTransform {
  constructor(public bd_service: BdService) {}

  transform(url: string, ...args: unknown[]): string {
    //console.log('transforming url: ', url);

    const full_url = this.bd_service.BASE_URL + '/storage/images/' + url;
    return url?.includes('data:') ? url : full_url;
  }
}
