import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BdService } from '../../../services/bd.service';
import { UserSession } from '../../../services/user-session.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Input() label: string;
  search: FormControl = new FormControl("");

  constructor(public userSession: UserSession,
    public route: ActivatedRoute,
    public router: Router,
    public utils_service: UtilsService,
    public bd_service: BdService) { }

  ngOnInit(): void {
  }

  go_search() {
    if (this.search.value == '') return;
    this.router.navigateByUrl('/blog/search/' + this.search.value);
  }

}
