<app-header></app-header>

<!-- hero section -->
<section [@slideInLeft] [@slideInRight]  class="d-flex flex-column align-items-center hero-back w-100 mb-3">
  <div class="section-content d-flex flex-column align-items-center hero-carousel-container position-relative">
    <!-- will be owl carousel after js loading -->
    <div class="will-be-owl-carousel owl-theme w-100 d-flex flex-column" #heroCarousel>
      <!-- hero carousel main-->
      <div class="d-flex flex-column-reverse flex-lg-row align-items-center hero-carousel mx-auto" *ngFor="let carousel of carousel_elements; let l = last" [attr.carousel_ready]="last ? false : true">
        {{ l === true ? initHeroCarousel() : null}}
        <!-- texts
        home_carousel_accent_title
        home_carousel_big_title
        home_carousel_content
        home_carousel_accent_button_text
        home_carousel_accent_button_link
        home_carousel_simple_button_text
        home_carousel_simple_button_link
        home_carousel_image
        -->
        <div class="texts d-flex flex-column align-items-start">
          <h1 class="primary medium bold left mb-1" [appRemoveOnNull]="carousel.contenu.home_carousel_accent_title">{{carousel.contenu.home_carousel_accent_title}}</h1>
          <p class="title font-4-em bold gray2 left mb-2" [appRemoveOnNull]="carousel.contenu.home_carousel_big_title">{{carousel.contenu.home_carousel_big_title}}</p>
          <p class="gray2 medium light left mb-2" [appRemoveOnNull]="carousel.contenu.home_carousel_content" [innerHTML]="carousel.contenu.home_carousel_content"></p>
          <div class="d-flex flex-row align-items-center justify-items-start mt-3">
            <a [appRouterOrHref]="{element: carousel, field_key: 'home_carousel_accent_button_link'}" class="cta cta-primary mr-4" [appRemoveOnNull]="carousel.contenu.home_carousel_accent_button_text">{{carousel.contenu.home_carousel_accent_button_text}}</a>
            <a [appRouterOrHref]="{element: carousel, field_key: 'home_carousel_simple_button_link'}" class="hover-to-primary gray2 medium bold title ml-4" [appRemoveOnNull]="carousel.contenu.home_carousel_simple_button_text">{{carousel.contenu.home_carousel_simple_button_text}}</a>
          </div>
        </div>
        <!-- illustration -->
        <div class="illustration d-flex flex-row align-items-end position-relative">
          <!-- illustration back -->
          <img src="../../../../assets/images/SVG/hero-ill-back.svg" alt="" class="hero-ill-back position-absolute w-100">
          <!-- ileira logo -->
          <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="ileira-logo position-absolute particlemove2">
          <!-- doctor image -->
          <div class="d-flex flex-row align-items-center doctor-image hero-image-to-clip position-absolute">
            <img [src]="carousel.contenu.home_carousel_image?.path | imgUrl" alt="" [appRemoveOnNull]="carousel.contenu.home_carousel_image?.path" class="hero-image-to-clip-img w-100 h-100">
          </div>
          <!-- ileira logo -->
          <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="ileira-logo-small position-absolute particlemove3">

          <!-- svg for illustration clipping -->
          <svg height="617" width="500" class="position-absolute">
            <defs>
              <clipPath id="hero-ill-clip-path">
                <path d="M499.5 0.5V366.137C499.5 503.929 387.792 615.637 250 615.637C112.208 615.637 0.5 503.929 0.5 366.137V0.5H499.5Z" stroke="black" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>

    <!-- resume -->
    <div class="resume header-shadow white-back d-flex flex-column flex-lg-row align-items-center justify-items-center w-100 py-4 px-5 position-absolute" *ngIf="service_une_phrase_type != undefined">
      <!-- feature -->
      <p class="gray2 left feature mt-4 mx-5" [appRemoveOnNull]="service.contenu.one_phrase_service_element_title" *ngFor="let service of service_une_phrase_elements">
        <span class="title bold medium mb-2">{{service.contenu.one_phrase_service_element_title}}</span> <br>
        <span class="small regular" [innerHTML]="service.contenu.one_phrase_service_element_content | sanitizeHtml"></span>
      </p>
      <!-- En Savoir + -->
      <a [appRouterOrHref]="{element: service_une_phrase_type, field_key: 'one_phrase_service_button_link'}" [appRemoveOnNull]="service_une_phrase_type.contenu.one_phrase_service_button_text" class="cta cta-primary mr-4 d-flex">{{service_une_phrase_type.contenu.one_phrase_service_button_text}}</a>
    </div>
  </div>
</section>

<!-- qui sommes nous section -->
<section [@slideInLeft] [@slideInRight]  class="qui-sommes-nous d-flex flex-row align-items-center justify-content-center w-100 mt-7 mb-7" *ngIf="qui_sommes_nous_type != undefined">
  <div class="section-content d-flex flex-column-reverse flex-lg-row align-items-stretch justify-content-center">
    <!-- illustrations -->
    <div class="illustrations position-relative w-40 mr-4">
      <img [src]="qui_sommes_nous_type.contenu.about_us_back_image?.path | imgUrl" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_back_image" alt="" class="w-80 position-absolute top-0 particlemove2">
      <img [src]="qui_sommes_nous_type.contenu.about_us_front_image?.path | imgUrl" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_front_image" alt="" class="w-80 position-absolute bottom-0 particlemove1">
    </div>
    <!-- texts
      -->
    <div class="texts d-flex flex-column align-items-start w-40 ml-5">
      <p class="primary medium bold left mb-1" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_accent_title">{{qui_sommes_nous_type.contenu.about_us_accent_title}}</p>
      <h1 class="title font-3-em bold gray2 left mb-2" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_big_title">{{qui_sommes_nous_type.contenu.about_us_big_title}}</h1>
      <p class="gray2 medium light left mt-4 qui_sommes_nous_content" [innerHTML]="qui_sommes_nous_type.contenu.about_us_content | sanitizeHtml" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_content"></p>

      <!-- Prise en charge -->
      <div class="d-flex flex-column align-items-start mt-4">
        <p class="gray2 medium bold" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_extra_enum_title">{{qui_sommes_nous_type.contenu.about_us_extra_enum_title}}</p>
        <div class="d-flex flex-row align-items-center mt-1 wrap">
          <!-- Médicale -->
          <p class="d-flex flex-row align-items-center mr-4" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_extra_enum_content" *ngFor="let extra of qui_sommes_nous_type.contenu.about_us_extra_enum_content">
            <img src="../../../../assets/images/SVG/check-green.svg" alt="" class="h-2em mr-2">
            <span class="small regular">{{extra}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- stats section -->
<section [@slideInLeft] [@slideInRight]  class="stats-section d-flex flex-row align-items-center justify-content-center w-100 mb-0 mt-7 stats-back p-4" *ngIf="stats_type != undefined">
  <div class="section-content d-flex flex-column flex-lg-row justify-content-around align-items-center">
    <!-- stat-->
    <div class="stat d-flex flex-column align-items-start" *ngFor="let stat of stats_elements; let l = last">
      {{ l === true ? initStatCount() : null}}
      <img [src]="stat.contenu.stat_counter_element_icon?.path | imgUrl" [appRemoveOnNull]="stat.contenu.stat_counter_element_icon" alt="" class="h-3em">
      <!-- counter -->
      <p class="stats-digits title bold font-45-em white left mb-0 mt-3" [appRemoveOnNull]="stat.contenu.stat_counter_element_value">
        {{stat.contenu.stat_counter_element_prefix}}
        <span [attr.delay]="statsCounterDelay" [attr.data-num]="stat.contenu.stat_counter_element_value" class="countfect annees-counter"></span>
        {{stat.contenu.stat_counter_element_suffix}}
      </p>
      <p class="small bold white left" [appRemoveOnNull]="stat.contenu.stat_counter_element_title">{{stat.contenu.stat_counter_element_title}}</p>
    </div>

  </div>

</section>

<!-- pourquoi nous choisir section -->
<section [@slideInLeft] [@slideInRight]  class="pourquoi-nous-choisir-section d-flex flex-column align-items-center w-100 mb-5 mt-0 standard-section-back p-5" *ngIf="pourquoi_nous_choisir_type != undefined">
  <div class="section-content d-flex flex-column flex-lg-row justify-content-center align-items-stretch mt-7 mb-7">
    <!-- texts
        "why_choose_us_accent_title
        "why_choose_us_big_title
        "why_choose_us_content
        "why_choose_us_extra_enum_title
        "why_choose_us_extra_enum_content
        "why_choose_us_accent_button_text
        "why_choose_us_accent_button_link
        "why_choose_us_simple_button_text
        "why_choose_us_simple_button_link
        "why_choose_us_media-->
    <div class="texts d-flex flex-column align-items-start w-40 mr-5">
      <p class="primary medium bold left mb-1" [appRemoveOnpourquoi_nous_choisir_type.contenu.Null]="why_choose_us_accent_title">{{pourquoi_nous_choisir_type.contenu.why_choose_us_accent_title}}</p>
      <p class="title font-3-em bold gray2 left mb-2" [appRemoveOnNull]="pourquoi_nous_choisir_type.contenu.why_choose_us_big_title">{{pourquoi_nous_choisir_type.contenu.why_choose_us_big_title}}</p>
      <p class="gray2 medium light left mt-4" [innerHTML]="pourquoi_nous_choisir_type.contenu.why_choose_us_content | sanitizeHtml" [appRemoveOnNull]="pourquoi_nous_choisir_type.contenu.why_choose_us_content"></p>

      <div class="d-flex flex-column align-items-start mt-4">
        <p class="gray2 medium bold" [appRemoveOnNull]="pourquoi_nous_choisir_type.contenu.why_choose_us_extra_enum_title">{{pourquoi_nous_choisir_type.contenu.why_choose_us_extra_enum_title}}</p>
        <div class="d-flex flex-row align-items-center mt-1 wrap">
          <!-- Médicale -->
          <p class="d-flex flex-row align-items-center mr-4" *ngFor="let content of pourquoi_nous_choisir_type.contenu.why_choose_us_extra_enum_content">
            <img src="../../../../assets/images/SVG/check-circle.svg" alt="" class="h-2em mr-2">
            <span class="small regular">{{content}}</span>
          </p>
        </div>
      </div>

      <div class="d-flex flex-lg-row flex-column align-items-lg-center align-items-start mt-3">
        <!-- Devenir Membre Maintenant → -->
        <a [appRouterOrHref]="{element: pourquoi_nous_choisir_type, field_key: 'why_choose_us_accent_button_link'}" class="cta cta-primary mr-4 d-flex" [appRemoveOnNull]="pourquoi_nous_choisir_type.contenu.why_choose_us_accent_button_link">{{pourquoi_nous_choisir_type.contenu.why_choose_us_accent_button_text}}</a>
        <a [appRouterOrHref]="{element: pourquoi_nous_choisir_type, field_key: 'why_choose_us_simple_button_link'}" class="hover-to-primary gray2 mt-lg-0 mt-3 medium bold title ml-4" [appRemoveOnNull]="pourquoi_nous_choisir_type.contenu.why_choose_us_simple_button_text">{{pourquoi_nous_choisir_type.contenu.why_choose_us_simple_button_text}}</a>
      </div>
    </div>
    <div class="video-anchor d-flex flex-row align-items-center w-45 h-100" #videoSidebar *ngIf="pourquoi_nous_choisir_type != undefined">
      <a href="https://www.youtube.com/watch?v=1ZYbU82GVz4" class="video-placeholder h-100 w-100 position-relative" [appRemoveOnNull]="pourquoi_nous_choisir_type.contenu.why_choose_us_media">
        <img [src]="pourquoi_nous_choisir_type.contenu.why_choose_us_media?.path | imgUrl" alt="" class="placeholder position-relative">
        <div class="d-flex flex-row align-items-center video-btn position-absolute">
          <img src="../../../../assets/images/SVG/big-play-button.svg" alt="" class="mx-auto h-2-em">
        </div>
      </a>
    </div>

  </div>
</section>

<!-- nos produits section services_type services_elements

      our_products_accent_title
      our_products_big_title
      our_products_content
      our_products_accent_button_text
      our_products_accent_button_link
      our_products_order_by
    -->
<section [@slideInLeft] [@slideInRight]  class="nos-produits-section d-flex flex-column align-items-center w-100 mb-5 mt-0 white-back p-5" *ngIf="services_type != undefined">
  <div class="section-content head d-flex flex-column align-items-center produits-carousel-container position-relative">
    <!-- floating logo -->
    <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="particlemove2 floating-logo position-absolute">
    <!-- texts -->
    <div class="texts d-flex flex-column align-items-start w-40 mr-auto mb-5">
      <p class="primary medium bold left mb-1" [appRemoveOnNull]="services_type.contenu.our_products_accent_title" >{{services_type.contenu.our_products_accent_title}}</p>
      <p class="title font-3-em bold gray2 left mb-2" [appRemoveOnNull]="services_type.contenu.our_products_big_title" >{{services_type.contenu.our_products_big_title}}</p>
      <p class="gray2 medium light left mt-2" style="max-height: 11em; overflow-y: hidden;"  [innerHTML]="services_type.contenu.our_products_content | sanitizeHtml" [appRemoveOnNull]="services_type.contenu.our_products_content" ></p>
    </div>
    <!-- features -->
    <div class="features d-flex flex-row align-items-stretch will-be-owl-carousel position-relative mt2">
      <!-- feature
      our_products_element_title
      our_products_element_content
      our_products_element_accent_button_text
      our_products_element_accent_button_link
      our_products_element_icon
      our_products_element_image
      our_products_element_priority
          -->
      <div class="feature white-back features-shadow d-flex flex-column align-items-center p-4 mx-2" *ngFor="let service of services_elements; let l = last" [attr.carousel_ready]="last ? false : true">
        {{ l === true ? initServicesCarousel() : null}}
        <img [src]="service.contenu.our_products_element_icon?.path | imgUrl" [appRemoveOnNull]="service.contenu.our_products_element_icon?.path" alt="" class="feature-ill mb-2">
        <p class="gray2 title bold medium center" [appRemoveOnNull]="service.contenu.our_products_element_content">{{service.contenu.our_products_element_title}}</p>
        <p class="small regular gray2 center mb-2 nos-produits-section-p"  [appRemoveOnNull]="service.contenu.our_products_element_content" 
        [innerHTML]="service.contenu.our_products_element_content.length < 170 ? service.contenu.our_products_element_content : service.contenu.our_products_element_content.substr(0, 170) | sanitizeHtml"></p>
        <a [appRouterOrHref]="{element: service, field_key: 'our_products_element_accent_button_link'}" [appRemoveOnNull]="service.contenu.our_products_element_accent_button_text" class="primary hover-to-accent bold mb-3">{{service.contenu.our_products_element_accent_button_text}}</a>
      </div>
    </div>
    <!-- Devenir Membre Maintenant → -->
    <a [appRouterOrHref]="{element: services_type, field_key: 'our_products_accent_button_link'}" [appRemoveOnNull]="services_type.contenu.our_products_accent_button_text" class="cta cta-primary mr-4 d-flex mt-6 mb-3">{{services_type.contenu.our_products_accent_button_text}}</a>
  </div>
</section>

<!-- blog section acceuil_blog_type maxArticles
    home_blog_accent_title
    home_blog_big_title
    home_blog_content
    home_blog_item_count
    home_blog_accent_button_text
    home_blog_accent_button_link
    home_blog_order_by
    -->
<section [@slideInLeft] [@slideInRight]  class="blog-section d-flex flex-column align-items-center w-100 mb-5 p-5" *ngIf="acceuil_blog_type != undefined">
  <div class="section-content head d-flex flex-column align-items-center position-relative">
    <!-- floating logo -->
    <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="particlemove2 floating-logo2 position-absolute">
    <!-- head -->
    <div class="head d-flex flex-lg-row flex-column align-items-end mb-5 w-100">
      <!-- texts -->
      <div class="texts d-flex flex-column align-items-start w-40 mr-auto">
        <p class="primary medium bold left mb-1" [appRemoveOnNull]="acceuil_blog_type.contenu.home_blog_accent_title">{{acceuil_blog_type.contenu.home_blog_accent_title}}</p>
        <p class="title font-3-em bold gray2 left mb-2" [appRemoveOnNull]="acceuil_blog_type.contenu.home_blog_big_title">{{acceuil_blog_type.contenu.home_blog_big_title}}</p>
        <p class="gray2 medium light left mt-2" [appRemoveOnNull]="acceuil_blog_type.contenu.home_blog_content" [innerHTML]="acceuil_blog_type.contenu.home_blog_content"></p>
      </div>
      <!-- Voir toute l’actualité → -->
      <a [appRouterOrHref]="{element: acceuil_blog_type, field_key: 'home_blog_accent_button_link'}" [appRemoveOnNull]="acceuil_blog_type.contenu.home_blog_accent_button_text" class="cta cta-primary mr-4 d-flex mt-6 mb-3">{{acceuil_blog_type.contenu.home_blog_accent_button_text}}</a>
    </div>
    <!-- articles -->
    <app-articles-list [articles]="articles"></app-articles-list>

  </div>
</section>


<!-- testimon ials section  testimonials_type testimonials_elements-->
<section [@slideInLeft] [@slideInRight]  class="testimonials-section d-flex flex-column align-items-center w-100 mb-0 mt-0 white-back p-5 position-relative" *ngIf="testimonials_type != undefined">
  <!-- floating logo -->
  <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="particlemove2 floating-logo2 position-absolute">
  <!-- head
    home_testimonials_accent_title
    home_testimonials_big_title
    home_testimonials_content
    home_testimonials_order_by
    -->
  <div class="section-content head d-flex flex-column flex-lg-row align-items-end m-4">
    <!-- texts -->
    <div class="texts d-flex flex-column align-items-start w-40 mr-auto">
      <p class="primary medium bold left mb-1" [appRemoveOnNull]="testimonials_type.contenu.home_testimonials_accent_title">{{testimonials_type.contenu.home_testimonials_accent_title}}</p>
      <p class="title font-3-em bold gray2 left mb-2" [appRemoveOnNull]="testimonials_type.contenu.home_testimonials_big_title">{{testimonials_type.contenu.home_testimonials_big_title}}</p>
    </div>
    <p class="texts gray2 medium light left mt-2 w-40" [appRemoveOnNull]="testimonials_type.contenu.home_testimonials_content" [innerHTML]="testimonials_type.contenu.home_testimonials_content | sanitizeHtml"></p>
  </div>

  <!-- testimonials -->
  <div class="testimonials justify-content-center section-content d-flex flex-row align-items-stretch flex-wrap mt-5">
    <!-- testimonial
      home_testimonials_element_author_name
      home_testimonials_element_author_place
      home_testimonials_element_author_place_flag
      home_testimonials_element_content
      home_testimonials_element_author_image
      home_testimonials_element_priority
      -->
    <div class="testimonial features-shadow feature d-flex white-back flex-column align-items-center mx-3 py-4 px-5 position-relative" *ngFor="let testimonial of testimonials_elements; let i = index; let l = last" [ngClass]="{'emphasis': i == 1 || i == 4 || i == 7 || i == 10}">
      {{l === true ? setTestimonialsPopulated(): null}}
      <img [src]="'../../../../assets/images/SVG/' + ( i%2 != 0 ? 'quote.svg' : 'quote-primary.svg')" alt="" class="quote position-absolute" [ngClass]="{'particlemove1': i%2 != 0 && i%3 != 0, 'particlemove2': i%2 == 0, 'particlemove3': i%3 == 0}">
      <p class="text small regular gray2 center" [innerHTML]="testimonial.contenu.home_testimonials_element_content | sanitizeHtml" [appRemoveOnNull]="testimonial.contenu.home_testimonials_element_content"></p>
      <!-- author -->
      <div class="author d-flex position-absolute flex-column align-items-center" [ngClass]="{'particlemove1': i%2 != 0 && i%3 != 0, 'particlemove2': i%2 == 0, 'particlemove': i%3 == 0}">
        <img [src]="testimonial.contenu.home_testimonials_element_author_image?.path | imgUrl" [appRemoveOnNull]="testimonial.contenu.home_testimonials_element_author_image?.path" alt="" class="profile rounded-full white-border border border-7 mb-0 h-5em w-5em">
        <p class="titre bold gray2 medium center mb-0">{{testimonial.contenu.home_testimonials_element_author_name}}</p>
        <p class="small regular primary center" [appRemoveOnNull]="testimonial.contenu.home_testimonials_element_author_place">{{testimonial.contenu.home_testimonials_element_author_place}} <span [class]="'fi fi-' + testimonial.contenu.home_testimonials_element_author_place_flag"></span></p>
      </div>
    </div>


  </div>
</section>

<app-footer></app-footer>
