<div class="sidebar d-flex flex-column align-items-start w-100" *ngIf="blog_sidebar_type != undefined">
  <!-- recherche -->
  <app-search></app-search>
  <!-- categories -->
  <div class="categories d-flex flex-column align-items-start w-100 mt-4">
    <p class="title medium gray2 bold left mb-3 w-100"><span class="mr-7">Catégories</span> <a [routerLink]="['/blog/articles']" class="primary hover-to-accent bold small">• voir tout</a></p>
    <ul>
      <li class="left" *ngFor="let categorie of categories">
        <a [routerLink]="['/blog/categorie/' + categorie.slug]" class="gray2 bold hover-to-primary">{{categorie.nom}}</a>
      </li>
    </ul>
  </div>
  <!-- decouvrez
      blog_sidbar_video_title
      blog_sidbar_video_content
      blog_sidbar_video_back_image
          -->
  <div class="decouvrez d-flex flex-column align-items-start w-100 mt-4" #videoSidebar *ngIf="blog_sidebar_type != undefined">
    <p class="title medium gray2 bold left mb-2 w-100" [appRemoveOnNull]="blog_sidebar_type.contenu.blog_sidbar_video_title"><span class="">{{blog_sidebar_type.contenu.blog_sidbar_video_title}}</span></p>
    <p class="title small gray2 regular left mb-1 w-100" [appRemoveOnNull]="blog_sidebar_type.contenu.blog_sidbar_video_content" [innerHTML]="blog_sidebar_type.contenu.blog_sidbar_video_content | sanitizeHtml"></p>
    <div class="video-anchor d-flex flex-row align-items-center w-80">
      <a href="https://www.youtube.com/watch?v=1ZYbU82GVz4" class="video-placeholder h-100 w-100 position-relative" [appRemoveOnNull]="blog_sidebar_type.contenu.blog_sidbar_video_back_image">
        <img [src]="blog_sidebar_type.contenu.blog_sidbar_video_back_image?.path | imgUrl" alt="" class="placeholder position-relative">
        <div class="d-flex flex-row align-items-center video-btn position-absolute">
          <img src="../../../../assets/images/SVG/big-play-button.svg" alt="" class="mx-auto h-2-em">
        </div>
      </a>
    </div>
  </div>
  <!-- floating -->
  <div class="d-flex flex-column align-items-start w-100 mt-7  position-relative">
    <!-- floating logo -->
    <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="particlemove2 d-none d-lg-flex floating-logo2 position-absolute left-0 z-index--1">
    <!-- texts -->
  </div>

</div>
