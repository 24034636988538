import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserSession } from './services/user-session.service';
import { BdService } from './services/bd.service';
import { UtilsService } from './services/utils.service';
import { FileFactoryService } from './services/file-factory.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MontantifyPipe } from './pipes/montantify.pipe';
import { FourNumberifyPipe } from './pipes/four-numberify.pipe';
import { ApiUrlPipe } from './pipes/api-url.pipe';
import { AppUrlPipe } from './pipes/app-url.pipe';
import { ImgUrlPipe } from './pipes/img-url.pipe';
import { ServerCodesPipe } from './pipes/server-codes.pipe';
import { TransitionGroupItemDirective } from './directives/transition-group-item.directive';
import { ErrorComponent } from './components/pages/error/error.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { ArticlesListComponent } from './components/shared//articles-list/articles-list.component';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { OurServicesComponent } from './components/pages/our-services/our-services.component';
import { SimplePageComponent } from './components/pages/simple-page/simple-page.component';
import { RemoveOnNullDirective } from './directives/remove-on-null.directive';
import { RouterOrHrefDirective } from './directives/router-or-href.directive';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { SharedModule } from './components/shared/shared.module';
import { DirectiveModule } from './directives/directive.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    HomeComponent,
    AboutUsComponent,
    ContactComponent,
    FaqComponent,
    OurServicesComponent,
    SimplePageComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    CarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DirectiveModule,
    CommonModule
  ],
  providers: [
    UserSession,
    BdService,
    UtilsService,
    FileFactoryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
