/* eslint-disable prefer-const */
/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable curly */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */

import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Elements, TypeElements, Users } from '../interfaces';
import { BdService } from './bd.service';

@Injectable()
export class UserSession {


  public token: string = null;
  welcomMsg = false;
  comfirmSubscription: Subscription;
  public user: Users;
  public TOKEN_STORE_KEY = '_t';
  public TOKEN_EXP_STORE_KEY = '_ex';

  constructor(private router: Router,) {
  }

  has_verified_kyc() {
    return this.user.kyc_valide == 1;
  }

  has_verified_phone() {
    return this.user.telephone_verified_at != null && this.user.telephone_verified_at != undefined;
  }

  has_verified_mail() {
    return this.user.email_verified_at != null && this.user.email_verified_at != undefined;
  }

  get_element_by_contenu_field(contenu_field: string, contenu_field_value: string, elements: Elements[]): Elements {
    let element_res = undefined;
    elements.forEach(element => {
      //console.log(element.contenu[contenu_field], contenu_field_value, element.contenu[contenu_field] == contenu_field_value);

      if (element.contenu[contenu_field] == contenu_field_value) {
        element_res = element;
      }
    });

    return element_res;

  }

  get_element_field_value_by_slug_name(slug: string, element: Elements) {
    return element?.contenu[slug];
  }

  filter_elements_by_type(slug: string, elements: Elements[]): any[] {
    const matching_elements: any[] = [];

    elements.forEach(element => {

      if (typeof element.contenu == "string") element.contenu = JSON.parse(element.contenu);

      let element_type_contenu: any;
      try {
        if (typeof element.type_element.contenu == "string") {
          element_type_contenu = JSON.parse(element.type_element.contenu);
          element.type_element.contenu = element_type_contenu;
        } else {
          element_type_contenu = element.type_element.contenu;
        }
        if (element_type_contenu.slug == slug) {
          matching_elements.push(element);
        }
      } catch (error) { }
    });

    return matching_elements;
  }
  find_type_elements_by_slug(slug: string, types: TypeElements[]): TypeElements {
    return types.find(t => t.contenu.slug == slug);
  }


  get_route_slug(menu_element: Elements, bd_service: BdService) {
    return bd_service.ROUTE_CODE_MAPPING.find(r => r.name == menu_element?.contenu?.menu_item_element_page_anchor)?.slug;
  }

  getBase64Image(url, callback) {
    var img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = function () {
      var canvas = document.createElement("canvas");
      const self: any = this;
      canvas.width = self.width;
      canvas.height = self.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(self, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      callback(dataURL);
    };
    img.src = url;
  }

  setUser(user: Users): void {
    const self = this;
    this.user = user;
    if (user.photo_path == null) {
      /* self.getBase64Image("./../../assets/images/SVG/popover-avatar.svg", function (base64Img) {
        self.user.photo_path = base64Img;
        self.user = user;
      }); */
    } 

  }
  getUser(): Users {
    return this.user;
  }

  public is_user_a_client() {
    // console.log(this.hasOneOfRoles(['Client']));
    return this.hasOneOfRoles(['Client']);
  }

  setToken(tok: string): void {

    this.token = tok;
    if (_.isEmpty(this.token)) {
      //this.reinit();
      return;
    }
  }

  lock(): void {
    this.token = null;
  }

  reinit(): void {
    this.token = null;
  }
  getToken() {
    return this.token;
    // return localStorage.getItem('gcaisse_user');
  }
  getUserId(): number {
    return this.user.id;
  }



  /**
   * Allows to know if the user have one of those Role
   *
   * @param Role Role
   */
  hasOneOfRoles(roles: any[]) {
    //console.log('this.access', 'Role', Role);

    if (
      this.user.roles != null &&
      this.user.roles.length > 0 &&
      roles != null &&
      roles.length > 0
    ) {
      let found = 0;
      for (const role of roles) {
        for (const r of this.user.roles) {
          if (r['libelle'] === role) {
            found++;
            break;
          }
        }
      }

      console.log(
        'found',
        found,
        'Role',
        roles,
        'this.Role',
        this.user.roles
      );

      if (found > 0) {
        return true;
      }
    }
  }

  public userMsgs = [
    'Liste',
    'Liste Avancée',
    'Texte',
    'Nombre',
    'Civilités',
    'Unités de tarification',
    'ELEMENTS_BY_PAGE_VIEW',
    'DEFAULT_USER_PHOTO',
    'Tarif',
    'Tarif (long)',
    'Nbre de jours',
    'Code volume',
    'Tarif',
    'Unité',
    'Devise',
    'Continent',
    'Pays',
    'Ville',
    'Quartier',
    'EN ATTENTE',
    'PAYÉE',
    'CONCLUE',
    'EN COURS',
    'TERMINÉE',
    'LOCATION_MAX_DAY_TIME',
    'LOCATION_MIN_DAY_TIME',
  ];
  encodeUserMsg(msg: string): string {
    switch (msg) {
      case 'Liste':
        return 'ARRAY';
      case 'Liste Avancée':
        return 'JSON';
      case 'Texte':
        return 'STRING';
      case 'Nombre':
        return 'INT';
      case 'Civilités':
        return 'CIVILITES';
      case 'Unités de tarification':
        return 'TARIF_UNITS';
      case 'ELEMENTS_BY_PAGE_VIEW':
        return 'ELEMENTS_BY_PAGE_VIEW';
      case 'DEFAULT_USER_PHOTO':
        return 'DEFAULT_USER_PHOTO';
      case 'Tarif':
        return 'libelle_t_u_court';
      case 'Tarif (long)':
        return 'libelle_t_u_long';
      case 'Nbre de jours':
        return 'jour_factor';
      case 'Code volume':
        return 'code_volume';
      case 'Tarif':
        return 'tarif';
      case 'Unité':
        return 'tarif_unit';
      case 'Devise':
        return 'devise';
      case 'Continent':
        return 'continent';
      case 'Pays':
        return 'pays';
      case 'Ville':
        return 'ville';
      case 'Quartier':
        return 'quartier';
      case 'EN ATTENTE':
        return 'EN_ATTENTE';
      case 'PAYÉE':
        return 'PAYEE';
      case 'CONCLUE':
        return 'CONCLUE';
      case 'EN COURS':
        return 'EN_COURS';
      case 'TERMINÉE':
        return 'TERMINEE';
      case 'Heure de fin service':
        return 'LOCATION_MAX_DAY_TIME';
      case 'Heure de début service':
        return 'LOCATION_MIN_DAY_TIME';
      default:
        return msg;
    }
  }
  decodeServerMsg(msg: string): string {
    switch (msg) {
      case 'RESOURCES_ACCESS_DENIED':
        return "Vous n'êtes pas autorisé pour l'action que vous vous apprêtez à faire.";
      case 'SESSION_TOKEN_NOT_FOUND':
        return 'Aucune activité depuis 3600 secondes ou plus; \n veuillez vous reconnecter.';
      case 'BAD_SESSION_TOKEN':
        return 'Aucune activité depuis 3600 secondes ou plus; \n veuillez vous reconnecter.';
      case 'USER_NOT_ACTIVE':
        return 'Vous êtes pas autorisé(e) à accéder à cette application.';
      case 'RECORD_EXIST':
        return 'Cet enregistrement existe déjà.';
      case 'RECORD_TO_UPDATE_NOT_EXIST':
        return "Impossible d'exécuter cette action.";
      case 'RECORD_TO_DELETE_NOT_EXIST':
        return "Cet élémeent n'existe pas ou a été déjà supprimé.";
      case 'INPUTS_ERRORS':
        return 'Vérifier les informations saisies';
      case 'RESOURCES_IS_USED':
        return 'Impossible de supprimer cet élément. Il fait déjà partie intégrante du système.';
      case 'DUPLICATE_RECORD':
        return 'Cet enregistrement existe déjà. Risque de doublon!';
      case 'NO_RECORDS_FOUND':
        return "Aucun enregistrement n'est trouvé.";
      case 'NO_NETWORK':
        return 'Veuillez vérifier votre connexion internet et réessayez.';
      case 'TRANSPORTEUR_INFORMATIONS_NOT_COMPLETE':
        return 'Les informations liées au transporteur sont incomplètes.';
      case 'TRANSPORTEUR_INFORMATIONS_COMPLETE':
        return 'Les informations liées au transporteur sont complètes.';
      case 'PRINT_ERROR':
        return "Une erreur inatendue est survenue lors de l'impression.";
      case 'CREDENTIALS_NOT_CORRECT':
        return 'Identifiants non corrects.';
      case 'TRANSPORTEUR_HAVE_NO_VEHICULE ':
        return 'Ce transporteur ne dispose pas encore de véhicule dans sa flotte.';
      default:
        return msg;
    }
  }
}
