/* eslint-disable guard-for-in */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable curly */
/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
import { Alertable, DateObject } from '../interfaces';
import { Injectable } from '@angular/core';

import { isInteger, isString } from 'lodash';

@Injectable()
export class UtilsService {
  public LOUABLES_PLACEHOLDER = '/assets/images/placeholder.svg';
  SVG_CONSTS: any;
  constructor() { }

  /*******************************
* custom alert methods
******************************/
  /**
   * Display an alert with the given title and message
   * @param context the component context
   * @param alert_id the id of the alert holder in the component's HTML template
   * @param title the display title of the success alert
   * @param message the display message of the success alert
   * @param delay delay of auto dissmiss in milliseconds. 0 means no auto dissmiss. 5000 is default
   */
  public success_alert(context: Alertable, alert_id: string, title: string, message: string, delay?: number) {
    this.show_alert(context, alert_id, title, message, "success", "check", delay);
  }
  /**
 * Display an alert with the given title and message
 * @param context the component context
 * @param alert_id the id of the alert holder in the component's HTML template
 * @param title the display title of the error alert
 * @param message the display message of the error alert
 * @param delay delay of auto dissmiss in milliseconds. 0 means no auto dissmiss. 5000 is default
 */
  public error_alert(context: Alertable, alert_id: string, title: string, message: string, delay?: number) {
    this.show_alert(context, alert_id, title, message, "danger", "times-circle", delay);
  }
  /**
 * Display an alert with the given title and message
 * @param context the component context
 * @param alert_id the id of the alert holder in the component's HTML template
 * @param title the display title of the warning alert
 * @param message the display message of the warning alert
 * @param delay delay of auto dissmiss in milliseconds. 0 means no auto dissmiss. 5000 is default
 */
  public warning_alert(context: Alertable, alert_id: string, title: string, message: string, delay?: number) {
    this.show_alert(context, alert_id, title, message, "warning", "warning", delay);
  }
  /**
  * Display an alert with the given title and message
  * @param context the component context
  * @param alert_id the id of the alert holder in the component's HTML template
  * @param title the display title of the info alert
  * @param message the display message of the info alert
  * @param delay delay of auto dissmiss in milliseconds. 0 means no auto dissmiss. 5000 is default
  */
  public info_alert(context: Alertable, alert_id: string, title: string, message: string, delay?: number) {
    this.show_alert(context, alert_id, title, message, "info", "info-circle", delay);
  }

  /**
   * Display an alert with the given title and message
   * @param context the component context
   * @param alert_id the id of the alert holder in the component's HTML template
   * @param title the display title of the alert
   * @param message the display message of the alert
   * @param type type of the alert. "info" is default
   * @param icon icon of the alert. "info-circle" is default
   * @param delay delay of auto dissmiss in milliseconds. 0 means no auto dissmiss. 5000 is default
   */
  public show_alert(context: Alertable, alert_id: string, title: string, message: string, type?: "success" | "info" | "warning" | "danger", icon?: "check" | "info-circle" | "warning" | "times-circle", delay?: number) {
    //console.log('showing alert', context);
    if (title == null) {
      switch (type) {
        case "danger":
          title = "Oups !";
          break;
        case "info":
          title = "Info :";
          break;
        case "success":
          title = "Succès";
          break;
        case "warning":
          title = "Attention !";
          break;

        default:
          break;
      }
    }
    //destroy existing instance
    context.showing_alert = undefined;
    //create a new instance
    context.showing_alert = {
      showing_alert_id: alert_id,
      alert_message: message,
      alert_title: title != undefined ? title : "",
      alert_type: type != undefined ? type : "info",
      alert_icon: icon != undefined ? icon : "info-circle",
    };

    if (delay != 0) {

      setTimeout(() => {
        //destroy that instance
        //context.showing_alert = undefined;
      }, delay !== undefined ? delay : 10000);
    }
  }

  /**
   *  Pour inverser une chaine de caractère
   */
  strInverse(str: string): string {
    let ret = '';
    const length = str.length;
    const tab: any[] = str.split('');
    for (let i = length - 1; i >= 0; i--) {
      ret = ret + tab[i];
    }
    return ret;
  }
  get_louables_tarif_factor(tarif_unit: string) {
    let factor = 0;
    switch (tarif_unit) {
      case 'Min':
        factor = 1 / 24 / 60;
        break;
      case 'Hr':
        factor = 1 / 24;
        break;
      case 'Jr':
        factor = 1;
        break;
      case 'Sem':
        factor = 1;
        break;
      case 'Mois':
        factor = 1;
        break;
      case 'An':
        factor = 1;
        break;

      default:
        break;
    }
    return factor;
  }

  escapeHtml(unsafe) {
    return unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }

  json_as_key_value_pair(
    json_object: any
  ): { key: string; value: any; type: 'string' | 'number' | 'array' }[] {
    const jsonToBeUsed = [];

    for (const type in json_object) {
      const item: any = {};
      item.key = type;
      item.value = json_object[type];
      if (isInteger(json_object[type])) {
        item.type = 'number';
      } else if (isString(json_object[type])) {
        item.type = 'string';
      } else {
        item.type = 'array';
        //item.value = "["+json_object[type]+"]";
      }
      jsonToBeUsed.push(item);
    }
    return jsonToBeUsed;
  }

  key_value_pair_to_json_simple(
    key_values: {
      key: string;
      value: string;
      type: 'string' | 'number' | 'array';
    }[],
    stringify_arrays?: boolean
  ): any {
    let input = '';
    key_values.forEach((key_value, index) => {
      const value =
        key_value.type == 'number'
          ? "'" + key_value.value + "'"
          : `'"` + key_value.value + `"'`;
      const key_value_string =
        (index == 0 ? '' : ' ') + key_value.key + '=' + value;
      input += key_value_string;
    });

    //console.log(input);

    const result = {};

    let position = 0;

    while (position < input.length) {
      let startKey = position;
      while (input[startKey] === ' ') startKey++;
      //console.log(input, startKey);
      const endKey = input.indexOf(`='`, startKey + 1);
      if (endKey === -1) throw new Error('Key without value');

      const endValue = input.indexOf(`'`, endKey + 2);
      if (endValue === -1) throw new Error('Unterminated Value');

      const key = input.slice(startKey, endKey);
      let value = input.slice(endKey + 2, endValue);
      // console.log(value);

      if (value.includes('"[') && value.includes(']"')) {
        //alors c'est un tableau et il faut seulement enlever les "" extremes
        //pour éviter que le covertisseur ne mette plein d'escapeurs comme \
        //et laisser telquel les "" des valeurs internes si'il y en a

        //mais il faut le faire si on l'appelant ne veut pas garder le tableau dans une chaine
        if (!stringify_arrays)
          value = value.replace('"[', '[').replace(']"', ']');
        result[key] = value;
      } else if (value.includes('"{') && value.includes('}"')) {
        //alors c'est un JSON et il faut seulement enlever les "" extremes
        //pour éviter que le covertisseur ne mette plein d'escapeurs comme \
        //et laisser telquel les "" des valeurs internes si'il y en a

        //mais il faut le faire si on l'appelant ne veut pas garder le JSON dans une chaine
        if (!stringify_arrays)
          value = value.replace('"{', '{').replace('}"', '}');
        result[key] = value;
      } else if (value.includes('"')) {
        //enlever les "" pour éviter que le covertisseur ne mette plein d'escapeurs comme \
        while (value.includes('"')) value = value.replace('"', '');
        result[key] = value;
      } else {
        //convertir la valeur en entier
        result[key] = +value;
      }

      //console.log('key', key, 'value', value);

      position = endValue + 1;
    }

    //console.log(result);
    return result;
  }

  string_date_timezone(): string {
    const offset: number = -new Date().getTimezoneOffset();
    const minutes_offset: number = Math.floor(offset / 60);
    const seconds_offset: number = offset % 60;
    const offset_string =
      (offset > 0 ? '+' : '-') +
      (minutes_offset < 10 ? '0' + minutes_offset : minutes_offset) +
      (seconds_offset < 10 ? '0' + seconds_offset : seconds_offset);
    return offset_string;
  }

  date_to_string(date: Date): string {
    if (date == undefined) return '';
    let date_string = '';
    date_string =
      this.twoNumberify(date.getDate()) +
      '-' +
      this.twoNumberify(date.getMonth() + 1) +
      '-' +
      this.twoNumberify(date.getFullYear());
    return date_string;
  }

  date_to_string_slash(date: Date): string {
    if (date == undefined) return '';
    let date_string = '';
    date_string =
      this.twoNumberify(date.getDate()) +
      '/' +
      this.twoNumberify(date.getMonth() + 1) +
      '/' +
      this.twoNumberify(date.getFullYear());
    return date_string;
  }

  date_to_string_reverse(date: Date): string {
    if (date == undefined) return '';
    let date_string = '';
    date_string =
      this.twoNumberify(date.getFullYear()) +
      '-' +
      this.twoNumberify(date.getMonth() + 1) +
      '-' +
      this.twoNumberify(date.getDate());
    return date_string;
  }

  string_to_string_reverse(string: string): string {
    if (string == null) return '';
    const date = this.string_to_date(string);
    return this.date_to_string_reverse(date);
  }

  string_reverse_to_string(string_reverse: string, delim?: string): string {
    if (string_reverse == null) return '';
    const date_delim =
      this.get_date_delimiter_from_datestring(string_reverse);
    const final_delim =
      delim == undefined
        ? this.get_date_delimiter_from_datestring(string_reverse)
        : delim;

    const string_reverse_array = string_reverse.split(date_delim);
    const string =
      string_reverse_array[2] +
      final_delim +
      string_reverse_array[1] +
      final_delim +
      string_reverse_array[0];
    return string;
  }
  string_compact_to_string(string_reverse: string, delim?: string): string {
    if (string_reverse == null) return '';

    const string_reverse_array = string_reverse.split("");
    // console.log(string_reverse_array);
    const string =
      string_reverse_array[6] + string_reverse_array[7] +
      delim +
      string_reverse_array[4] + string_reverse_array[5] +
      delim +
      string_reverse_array[0] + string_reverse_array[1] + string_reverse_array[2] + string_reverse_array[3];
    return string;
  }
  string_reverse_to_slash_string(string_reverse: string): string {
    if (string_reverse == null) return '';
    const date_delim =
      this.get_date_delimiter_from_datestring(string_reverse);
    const string_reverse_array = string_reverse.split(date_delim);
    let string = '';
    string =
      string_reverse_array[2] +
      '/' +
      string_reverse_array[1] +
      '/' +
      string_reverse_array[0];
    return string;
  }

  string_to_date(string: string): Date {
    if (string == null) return undefined;
    const date_delim = this.get_date_delimiter_from_datestring(string);
    if (string == null || string == 'null' || string == '')
      return new Date();
    const string_arr = string.split(date_delim);
    const date = new Date(
      +string_arr[2],
      +string_arr[1] - 1,
      +string_arr[0]
    );
    return date;
  }

  string_slash_to_date(string: string): Date {
    if (string == null) return undefined;
    const date_delim = this.get_date_delimiter_from_datestring(string);
    if (string == null || string == 'null' || string == '')
      return new Date();
    const string_arr = string.split(date_delim);
    const date = new Date(
      +string_arr[2],
      +string_arr[1] - 1,
      +string_arr[0]
    );
    return date;
  }

  string_to_date_object(string: string): DateObject {
    if (string == null) return undefined;
    const date_delim = this.get_date_delimiter_from_datestring(string);
    if (string == null || string == 'null' || string == '')
      return this.date_to_date_object(new Date());
    const string_arr = string.split(date_delim);
    const date = {
      date: +string_arr[0],
      month: +string_arr[1],
      year: +string_arr[2],
    };
    return date;
  }

  date_to_date_object(date: Date): DateObject {
    if (date == undefined) return undefined;
    const date_object = {
      date: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    return date_object;
  }
  date_object_to_string(date: DateObject): string {
    const date_string =
      this.twoNumberify(date.date) +
      '-' +
      this.twoNumberify(date.month) +
      '-' +
      this.twoNumberify(date.year);
    return date_string;
  }

  get_date_delimiter_from_datestring(str: string) {
    let date_delim = '-';
    for (let i = 0; i < str.length; i++) {
      const tmp_delim = str.charAt(i);
      if (
        tmp_delim == '/' ||
        tmp_delim == '-' ||
        tmp_delim == '.' ||
        tmp_delim == ' '
      ) {
        date_delim = tmp_delim;
        break;
      }
    }
    //console.log('date_delim = ' + date_delim);

    return date_delim;
  }

  twoNumberify(num: number): string {
    let res = '';
    if (num < 10) {
      res = '0' + num;
    } else {
      res = '' + num;
    }
    return res;
  }

  oneNumberify(num: string): number {
    let res = 0;
    res = +num;
    return res;
  }

  get_color_from_string(string: string): string {
    let color = 'white';
    string = string.toLocaleLowerCase();
    if (string.includes('rouge')) {
      color = 'red';
    } else if (string.includes('bleu')) {
      color = 'blue';
    } else if (string.includes('carte_mdise_inter_urbain')) {
      color = '#7fffd4';
    } else if (string.includes('carte_mdise_inter_nat')) {
      color = 'yellow';
    } else if (string.includes('carte_pers_inter_urbain')) {
      color = 'pink';
    } else if (string.includes('carte_pers_inter_nat')) {
      color = 'green';
    } else if (string.includes('primary')) {
      color = '#3fa9f5';
    } else if (string.includes('noir')) {
      color = 'black';
    } else if (string.includes('rose')) {
      color = 'pink';
    } else if (string.includes('orange')) {
      color = 'orange';
    } else if (string.includes('jaune')) {
      color = 'yellow';
    } else if (string.includes('vert')) {
      color = 'green';
    } else if (string.includes('#')) {
      color = string;
    }
    return color;
  }

  is_tel_number_key(event, value?: any, count?: number) {
    const char_code = event.which ? event.which : event.keyCode;

    if (value !== undefined) {
      const value_string: string = value;

      if (count === 1) {
        if (value_string.length < 8) {
          return this.is_number_key(event);
        } else {
          return false;
        }
      }

      if (value_string.length < 8) {
        return this.is_number_key(event);
      } else if (value_string.length === 8) {
        if (char_code === 59) {
          return true;
        }
        return false;
      } else if (value_string.length < 17) {
        return this.is_number_key(event);
      } else {
        return false;
      }
    }

    if (char_code > 31 && (char_code < 48 || char_code > 57)) {
      return false;
    }
    return true;
  }

  is_number_key(event) {
    const char_code = event.which ? event.which : event.keyCode;

    if (char_code > 31 && (char_code < 48 || char_code > 57)) {
      return false;
    }
    return true;
  }

  public capitalize(string: string): string {
    let capitalize = '';
    capitalize = string.charAt(0).toLocaleUpperCase() + string.substring(1);
    return capitalize;
  }

  /**
   * get ellipsised version of a text with ... to the end
   *
   * @param text
   * @param char_count
   * @param no_suffix?
   */
  ellipsise(text: string, char_count: number, no_suffix?: boolean): string {
    text = text.substring(0, char_count - 1) + (no_suffix ? '' : '...');
    return text;
  }

  /**
   *  Pour convertir un tableau de string en une seule string avec un séparateur
   */
  public array_to_string(array: string[], separator: string): string {
    let string = '';
    if (array.length === 0) return string;

    if (array.length >= 1) string += array[0];

    if (array.length > 1) {
      for (let i = 1; i < array.length; i++) {
        string += separator + array[i];
      }
    }
    return string;
  }

  public countries = [
    {
      NAME: 'BENIN',
      CODE: 'BJ',
    },
    {
      NAME: 'AFGHANISTAN',
      CODE: 'AF',
    },
    {
      NAME: 'ÅLAND ISLANDS',
      CODE: 'AX',
    },
    {
      NAME: 'ALBANIA',
      CODE: 'AL',
    },
    {
      NAME: 'ALGERIA',
      CODE: 'DZ',
    },
    {
      NAME: 'AMERICAN SAMOA',
      CODE: 'AS',
    },
    {
      NAME: 'ANDORRA',
      CODE: 'AD',
    },
    {
      NAME: 'ANGOLA',
      CODE: 'AO',
    },
    {
      NAME: 'ANGUILLA',
      CODE: 'AI',
    },
    {
      NAME: 'ANTARCTICA',
      CODE: 'AQ',
    },
    {
      NAME: 'ANTIGUA AND BARBUDA',
      CODE: 'AG',
    },
    {
      NAME: 'ARGENTINA',
      CODE: 'AR',
    },
    {
      NAME: 'ARMENIA',
      CODE: 'AM',
    },
    {
      NAME: 'ARUBA',
      CODE: 'AW',
    },
    {
      NAME: 'AUSTRALIA',
      CODE: 'AU',
    },
    {
      NAME: 'AUSTRIA',
      CODE: 'AT',
    },
    {
      NAME: 'AZERBAIJAN',
      CODE: 'AZ',
    },
    {
      NAME: 'BAHAMAS',
      CODE: 'BS',
    },
    {
      NAME: 'BAHRAIN',
      CODE: 'BH',
    },
    {
      NAME: 'BANGLADESH',
      CODE: 'BD',
    },
    {
      NAME: 'BARBADOS',
      CODE: 'BB',
    },
    {
      NAME: 'BELARUS',
      CODE: 'BY',
    },
    {
      NAME: 'BELGIUM',
      CODE: 'BE',
    },
    {
      NAME: 'BELIZE',
      CODE: 'BZ',
    },
    {
      NAME: 'BERMUDA',
      CODE: 'BM',
    },
    {
      NAME: 'BHUTAN',
      CODE: 'BT',
    },
    {
      NAME: 'BOLIVIA',
      CODE: 'BO',
    },
    {
      NAME: 'BOSNIA AND HERZEGOVINA',
      CODE: 'BA',
    },
    {
      NAME: 'BOTSWANA',
      CODE: 'BW',
    },
    {
      NAME: 'BOUVET ISLAND',
      CODE: 'BV',
    },
    {
      NAME: 'BRAZIL',
      CODE: 'BR',
    },
    {
      NAME: 'BRITISH INDIAN OCEAN TERRITORY',
      CODE: 'IO',
    },
    {
      NAME: 'BRUNEI DARUSSALAM',
      CODE: 'BN',
    },
    {
      NAME: 'BULGARIA',
      CODE: 'BG',
    },
    {
      NAME: 'BURKINA FASO',
      CODE: 'BF',
    },
    {
      NAME: 'BURUNDI',
      CODE: 'BI',
    },
    {
      NAME: 'CAMBODIA',
      CODE: 'KH',
    },
    {
      NAME: 'CAMEROON',
      CODE: 'CM',
    },
    {
      NAME: 'CANADA',
      CODE: 'CA',
    },
    {
      NAME: 'CAPE VERDE',
      CODE: 'CV',
    },
    {
      NAME: 'CAYMAN ISLANDS',
      CODE: 'KY',
    },
    {
      NAME: 'CENTRAL AFRICAN REPUBLIC',
      CODE: 'CF',
    },
    {
      NAME: 'CHAD',
      CODE: 'TD',
    },
    {
      NAME: 'CHILE',
      CODE: 'CL',
    },
    {
      NAME: 'CHINA',
      CODE: 'CN',
    },
    {
      NAME: 'CHRISTMAS ISLAND',
      CODE: 'CX',
    },
    {
      NAME: 'COCOS (KEELING) ISLANDS',
      CODE: 'CC',
    },
    {
      NAME: 'COLOMBIA',
      CODE: 'CO',
    },
    {
      NAME: 'COMOROS',
      CODE: 'KM',
    },
    {
      NAME: 'CONGO',
      CODE: 'CG',
    },
    {
      NAME: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
      CODE: 'CD',
    },
    {
      NAME: 'COOK ISLANDS',
      CODE: 'CK',
    },
    {
      NAME: 'COSTA RICA',
      CODE: 'CR',
    },
    {
      NAME: "COTE D'IVOIRE",
      CODE: 'CI',
    },
    {
      NAME: 'CROATIA',
      CODE: 'HR',
    },
    {
      NAME: 'CUBA',
      CODE: 'CU',
    },
    {
      NAME: 'CYPRUS',
      CODE: 'CY',
    },
    {
      NAME: 'CZECH REPUBLIC',
      CODE: 'CZ',
    },
    {
      NAME: 'DENMARK',
      CODE: 'DK',
    },
    {
      NAME: 'DJIBOUTI',
      CODE: 'DJ',
    },
    {
      NAME: 'DOMINICA',
      CODE: 'DM',
    },
    {
      NAME: 'DOMINICAN REPUBLIC',
      CODE: 'DO',
    },
    {
      NAME: 'ECUADOR',
      CODE: 'EC',
    },
    {
      NAME: 'EGYPT',
      CODE: 'EG',
    },
    {
      NAME: 'EL SALVADOR',
      CODE: 'SV',
    },
    {
      NAME: 'EQUATORIAL GUINEA',
      CODE: 'GQ',
    },
    {
      NAME: 'ERITREA',
      CODE: 'ER',
    },
    {
      NAME: 'ESTONIA',
      CODE: 'EE',
    },
    {
      NAME: 'ETHIOPIA',
      CODE: 'ET',
    },
    {
      NAME: 'FALKLAND ISLANDS (MALVINAS)',
      CODE: 'FK',
    },
    {
      NAME: 'FAROE ISLANDS',
      CODE: 'FO',
    },
    {
      NAME: 'FIJI',
      CODE: 'FJ',
    },
    {
      NAME: 'FINLAND',
      CODE: 'FI',
    },
    {
      NAME: 'FRANCE',
      CODE: 'FR',
    },
    {
      NAME: 'FRENCH GUIANA',
      CODE: 'GF',
    },
    {
      NAME: 'FRENCH POLYNESIA',
      CODE: 'PF',
    },
    {
      NAME: 'FRENCH SOUTHERN TERRITORIES',
      CODE: 'TF',
    },
    {
      NAME: 'GABON',
      CODE: 'GA',
    },
    {
      NAME: 'GAMBIA',
      CODE: 'GM',
    },
    {
      NAME: 'GEORGIA',
      CODE: 'GE',
    },
    {
      NAME: 'GERMANY',
      CODE: 'DE',
    },
    {
      NAME: 'GHANA',
      CODE: 'GH',
    },
    {
      NAME: 'GIBRALTAR',
      CODE: 'GI',
    },
    {
      NAME: 'GREECE',
      CODE: 'GR',
    },
    {
      NAME: 'GREENLAND',
      CODE: 'GL',
    },
    {
      NAME: 'GRENADA',
      CODE: 'GD',
    },
    {
      NAME: 'GUADELOUPE',
      CODE: 'GP',
    },
    {
      NAME: 'GUAM',
      CODE: 'GU',
    },
    {
      NAME: 'GUATEMALA',
      CODE: 'GT',
    },
    {
      NAME: 'GUERNSEY',
      CODE: 'GG',
    },
    {
      NAME: 'GUINEA',
      CODE: 'GN',
    },
    {
      NAME: 'GUINEA-BISSAU',
      CODE: 'GW',
    },
    {
      NAME: 'GUYANA',
      CODE: 'GY',
    },
    {
      NAME: 'HAITI',
      CODE: 'HT',
    },
    {
      NAME: 'HEARD ISLAND AND MCDONALD ISLANDS',
      CODE: 'HM',
    },
    {
      NAME: 'HOLY SEE (VATICAN CITY STATE)',
      CODE: 'VA',
    },
    {
      NAME: 'HONDURAS',
      CODE: 'HN',
    },
    {
      NAME: 'HONG KONG',
      CODE: 'HK',
    },
    {
      NAME: 'HUNGARY',
      CODE: 'HU',
    },
    {
      NAME: 'ICELAND',
      CODE: 'IS',
    },
    {
      NAME: 'INDIA',
      CODE: 'IN',
    },
    {
      NAME: 'INDONESIA',
      CODE: 'ID',
    },
    {
      NAME: 'IRAN, ISLAMIC REPUBLIC OF',
      CODE: 'IR',
    },
    {
      NAME: 'IRAQ',
      CODE: 'IQ',
    },
    {
      NAME: 'IRELAND',
      CODE: 'IE',
    },
    {
      NAME: 'ISLE OF MAN',
      CODE: 'IM',
    },
    {
      NAME: 'ISRAEL',
      CODE: 'IL',
    },
    {
      NAME: 'ITALY',
      CODE: 'IT',
    },
    {
      NAME: 'JAMAICA',
      CODE: 'JM',
    },
    {
      NAME: 'JAPAN',
      CODE: 'JP',
    },
    {
      NAME: 'JERSEY',
      CODE: 'JE',
    },
    {
      NAME: 'JORDAN',
      CODE: 'JO',
    },
    {
      NAME: 'KAZAKHSTAN',
      CODE: 'KZ',
    },
    {
      NAME: 'KENYA',
      CODE: 'KE',
    },
    {
      NAME: 'KIRIBATI',
      CODE: 'KI',
    },
    {
      NAME: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
      CODE: 'KP',
    },
    {
      NAME: 'KOREA, REPUBLIC OF',
      CODE: 'KR',
    },
    {
      NAME: 'KUWAIT',
      CODE: 'KW',
    },
    {
      NAME: 'KYRGYZSTAN',
      CODE: 'KG',
    },
    {
      NAME: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
      CODE: 'LA',
    },
    {
      NAME: 'LATVIA',
      CODE: 'LV',
    },
    {
      NAME: 'LEBANON',
      CODE: 'LB',
    },
    {
      NAME: 'LESOTHO',
      CODE: 'LS',
    },
    {
      NAME: 'LIBERIA',
      CODE: 'LR',
    },
    {
      NAME: 'LIBYAN ARAB JAMAHIRIYA',
      CODE: 'LY',
    },
    {
      NAME: 'LIECHTENSTEIN',
      CODE: 'LI',
    },
    {
      NAME: 'LITHUANIA',
      CODE: 'LT',
    },
    {
      NAME: 'LUXEMBOURG',
      CODE: 'LU',
    },
    {
      NAME: 'MACAO',
      CODE: 'MO',
    },
    {
      NAME: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
      CODE: 'MK',
    },
    {
      NAME: 'MADAGASCAR',
      CODE: 'MG',
    },
    {
      NAME: 'MALAWI',
      CODE: 'MW',
    },
    {
      NAME: 'MALAYSIA',
      CODE: 'MY',
    },
    {
      NAME: 'MALDIVES',
      CODE: 'MV',
    },
    {
      NAME: 'MALI',
      CODE: 'ML',
    },
    {
      NAME: 'MALTA',
      CODE: 'MT',
    },
    {
      NAME: 'MARSHALL ISLANDS',
      CODE: 'MH',
    },
    {
      NAME: 'MARTINIQUE',
      CODE: 'MQ',
    },
    {
      NAME: 'MAURITANIA',
      CODE: 'MR',
    },
    {
      NAME: 'MAURITIUS',
      CODE: 'MU',
    },
    {
      NAME: 'MAYOTTE',
      CODE: 'YT',
    },
    {
      NAME: 'MEXICO',
      CODE: 'MX',
    },
    {
      NAME: 'MICRONESIA, FEDERATED STATES OF',
      CODE: 'FM',
    },
    {
      NAME: 'MOLDOVA, REPUBLIC OF',
      CODE: 'MD',
    },
    {
      NAME: 'MONACO',
      CODE: 'MC',
    },
    {
      NAME: 'MONGOLIA',
      CODE: 'MN',
    },
    {
      NAME: 'MONTSERRAT',
      CODE: 'MS',
    },
    {
      NAME: 'MOROCCO',
      CODE: 'MA',
    },
    {
      NAME: 'MOZAMBIQUE',
      CODE: 'MZ',
    },
    {
      NAME: 'MYANMAR',
      CODE: 'MM',
    },
    {
      NAME: 'NAMIBIA',
      CODE: 'NA',
    },
    {
      NAME: 'NAURU',
      CODE: 'NR',
    },
    {
      NAME: 'NEPAL',
      CODE: 'NP',
    },
    {
      NAME: 'NETHERLANDS',
      CODE: 'NL',
    },
    {
      NAME: 'NETHERLANDS ANTILLES',
      CODE: 'AN',
    },
    {
      NAME: 'NEW CALEDONIA',
      CODE: 'NC',
    },
    {
      NAME: 'NEW ZEALAND',
      CODE: 'NZ',
    },
    {
      NAME: 'NICARAGUA',
      CODE: 'NI',
    },
    {
      NAME: 'NIGER',
      CODE: 'NE',
    },
    {
      NAME: 'NIGERIA',
      CODE: 'NG',
    },
    {
      NAME: 'NIUE',
      CODE: 'NU',
    },
    {
      NAME: 'NORFOLK ISLAND',
      CODE: 'NF',
    },
    {
      NAME: 'NORTHERN MARIANA ISLANDS',
      CODE: 'MP',
    },
    {
      NAME: 'NORWAY',
      CODE: 'NON',
    },
    {
      NAME: 'OMAN',
      CODE: 'OM',
    },
    {
      NAME: 'PAKISTAN',
      CODE: 'PK',
    },
    {
      NAME: 'PALAU',
      CODE: 'PW',
    },
    {
      NAME: 'PALESTINIAN TERRITORY, OCCUPIED',
      CODE: 'PS',
    },
    {
      NAME: 'PANAMA',
      CODE: 'PA',
    },
    {
      NAME: 'PAPUA NEW GUINEA',
      CODE: 'PG',
    },
    {
      NAME: 'PARAGUAY',
      CODE: 'PY',
    },
    {
      NAME: 'PERU',
      CODE: 'PE',
    },
    {
      NAME: 'PHILIPPINES',
      CODE: 'PH',
    },
    {
      NAME: 'PITCAIRN',
      CODE: 'PN',
    },
    {
      NAME: 'POLAND',
      CODE: 'PL',
    },
    {
      NAME: 'PORTUGAL',
      CODE: 'PT',
    },
    {
      NAME: 'PUERTO RICO',
      CODE: 'PR',
    },
    {
      NAME: 'QATAR',
      CODE: 'QA',
    },
    {
      NAME: 'REUNION',
      CODE: 'RE',
    },
    {
      NAME: 'ROMANIA',
      CODE: 'RO',
    },
    {
      NAME: 'RUSSIAN FEDERATION',
      CODE: 'RU',
    },
    {
      NAME: 'RWANDA',
      CODE: 'RW',
    },
    {
      NAME: 'SAINT HELENA',
      CODE: 'SH',
    },
    {
      NAME: 'SAINT KITTS AND NEVIS',
      CODE: 'KN',
    },
    {
      NAME: 'SAINT LUCIA',
      CODE: 'LC',
    },
    {
      NAME: 'SAINT PIERRE AND MIQUELON',
      CODE: 'PM',
    },
    {
      NAME: 'SAINT VINCENT AND THE GRENADINES',
      CODE: 'VC',
    },
    {
      NAME: 'SAMOA',
      CODE: 'WS',
    },
    {
      NAME: 'SAN MARINO',
      CODE: 'SM',
    },
    {
      NAME: 'SAO TOME AND PRINCIPE',
      CODE: 'ST',
    },
    {
      NAME: 'SAUDI ARABIA',
      CODE: 'SA',
    },
    {
      NAME: 'SENEGAL',
      CODE: 'SN',
    },
    {
      NAME: 'SERBIA AND MONTENEGRO',
      CODE: 'CS',
    },
    {
      NAME: 'SEYCHELLES',
      CODE: 'SC',
    },
    {
      NAME: 'SIERRA LEONE',
      CODE: 'SL',
    },
    {
      NAME: 'SINGAPORE',
      CODE: 'SG',
    },
    {
      NAME: 'SLOVAKIA',
      CODE: 'SK',
    },
    {
      NAME: 'SLOVENIA',
      CODE: 'SI',
    },
    {
      NAME: 'SOLOMON ISLANDS',
      CODE: 'SB',
    },
    {
      NAME: 'SOMALIA',
      CODE: 'SO',
    },
    {
      NAME: 'SOUTH AFRICA',
      CODE: 'ZA',
    },
    {
      NAME: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
      CODE: 'GS',
    },
    {
      NAME: 'SPAIN',
      CODE: 'ES',
    },
    {
      NAME: 'SRI LANKA',
      CODE: 'LK',
    },
    {
      NAME: 'SUDAN',
      CODE: 'SD',
    },
    {
      NAME: 'SURI',
      CODE: 'SR',
    },
    {
      NAME: 'SVALBARD AND JAN MAYEN',
      CODE: 'SJ',
    },
    {
      NAME: 'SWAZILAND',
      CODE: 'SZ',
    },
    {
      NAME: 'SWEDEN',
      CODE: 'SE',
    },
    {
      NAME: 'SWITZERLAND',
      CODE: 'CH',
    },
    {
      NAME: 'SYRIAN ARAB REPUBLIC',
      CODE: 'SY',
    },
    {
      NAME: 'TAIWAN, PROVINCE OF CHINA',
      CODE: 'TW',
    },
    {
      NAME: 'TAJIKISTAN',
      CODE: 'TJ',
    },
    {
      NAME: 'TANZANIA, UNITED REPUBLIC OF',
      CODE: 'TZ',
    },
    {
      NAME: 'THAILAND',
      CODE: 'TH',
    },
    {
      NAME: 'TIMOR-LESTE',
      CODE: 'TL',
    },
    {
      NAME: 'TOGO',
      CODE: 'TG',
    },
    {
      NAME: 'TOKELAU',
      CODE: 'TK',
    },
    {
      NAME: 'TONGA',
      CODE: 'TO',
    },
    {
      NAME: 'TRINIDAD AND TOBAGO',
      CODE: 'TT',
    },
    {
      NAME: 'TUNISIA',
      CODE: 'TN',
    },
    {
      NAME: 'TURKEY',
      CODE: 'TR',
    },
    {
      NAME: 'TURKMENISTAN',
      CODE: 'TM',
    },
    {
      NAME: 'TURKS AND CAICOS ISLANDS',
      CODE: 'TC',
    },
    {
      NAME: 'TUVALU',
      CODE: 'TV',
    },
    {
      NAME: 'UGANDA',
      CODE: 'UG',
    },
    {
      NAME: 'UKRAINE',
      CODE: 'UA',
    },
    {
      NAME: 'UNITED ARAB EMIRATES',
      CODE: 'AE',
    },
    {
      NAME: 'UNITED KINGDOM',
      CODE: 'GB',
    },
    {
      NAME: 'UNITED STATES',
      CODE: 'US',
    },
    {
      NAME: 'UNITED STATES MINOR OUTLYING ISLANDS',
      CODE: 'UM',
    },
    {
      NAME: 'URUGUAY',
      CODE: 'UY',
    },
    {
      NAME: 'UZBEKISTAN',
      CODE: 'UZ',
    },
    {
      NAME: 'VANUATU',
      CODE: 'VU',
    },
    {
      NAME: 'VENEZUELA',
      CODE: 'VE',
    },
    {
      NAME: 'VIET NAM',
      CODE: 'VN',
    },
    {
      NAME: 'VIRGIN ISLANDS, BRITISH',
      CODE: 'VG',
    },
    {
      NAME: 'VIRGIN ISLANDS, U.S.',
      CODE: 'VI',
    },
    {
      NAME: 'WALLIS AND FUTUNA',
      CODE: 'WF',
    },
    {
      NAME: 'WESTERN SAHARA',
      CODE: 'EH',
    },
    {
      NAME: 'YEMEN',
      CODE: 'YE',
    },
    {
      NAME: 'ZAMBIA',
      CODE: 'ZM',
    },
    {
      NAME: 'ZIMBABWE',
      CODE: 'ZW',
    },
  ];
}
