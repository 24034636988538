<div class="recherche d-flex flex-column align-items-start">
  <p class="title medium gray2 bold left mb-2 w-100" *ngIf="label == undefined || label == 'yes'">Recherche</p>
  <div class="d-flex flex-row flex-wrap align-items-center position-relative justify-content-center">
    <!-- seach -->
    <div class="newsletter-input newsletter-input-no-cta d-flex flex-row align-items-center white-back border m-2 gray5-border">
      <i class="fa fa-search mr-2"></i>
      <input type="text" placeholder="Tapez un terme" (keyup.enter)="go_search()" [formControl]="search" class="my-auto">
    </div>
  </div>
</div>
