import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'serverCodes',
})
export class ServerCodesPipe implements PipeTransform {
    transform(code: string, ...args: unknown[]): string {
        switch (code) {
            case 'ARRAY':
                return 'Liste';
            case 'JSON':
                return 'Liste Avancée';
            case 'STRING':
                return 'Texte';
            case 'INT':
                return 'Nombre';
            case 'CIVILITES':
                return 'Civilités';
            case 'TARIF_UNITS':
                return 'Unités de tarification';
            case 'ELEMENTS_BY_PAGE_VIEW':
                return 'ELEMENTS_BY_PAGE_VIEW';
            case 'DEFAULT_USER_PHOTO':
                return 'DEFAULT_USER_PHOTO';
            case 'libelle_t_u_court':
                return 'Tarif';
            case 'libelle_t_u_long':
                return 'Tarif (long)';
            case 'jour_factor':
                return 'Nbre de jours';
            case 'code_volume':
                return 'Code volume';
            case 'tarif':
                return 'Tarif';
            case 'tarif_unit':
                return 'Unité';
            case 'devise':
                return 'Devise';
            case 'continent':
                return 'Continent';
            case 'pays':
                return 'Pays';
            case 'ville':
                return 'Ville';
            case 'quartier':
                return 'Quartier';
            case 'EN_ATTENTE':
                return 'EN ATTENTE';
            case 'PAYEE':
                return 'PAYÉE';
            case 'CONCLUE':
                return 'CONCLUE';
            case 'EN_COURS':
                return 'EN COURS';
            case 'TERMINEE':
                return 'TERMINÉE';
            case 'M.':
                return 'Monsieur';
            case 'Mme.':
                return 'Madame';
            case 'Mlle.':
                return 'Mademoiselle';
            case 'Dr.':
                return 'Docteur';
            case 'LOCATION_MAX_DAY_TIME':
                return 'Heure de fin service';
            case 'LOCATION_MIN_DAY_TIME':
                return 'Heure de début service';
            default:
                return code;
        }
    }
}
