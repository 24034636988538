import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BdService } from './services/bd.service';
import { fuseAnimations } from './components/shared/animations';
declare var $: any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [fuseAnimations],
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'web';
  old_route: string;
  time: number;
  routerSubscription: any;
  constructor(public router: Router, public bd_serice: BdService) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log('bool', this.router.url.toString() != this.old_route, this.router.url.toString(), this.old_route);
        this.bd_serice.get_data();
        let time_ = new Date().getTime();
        //console.log('time', this.time, time_);
        this.time = time_;

        this.old_route = this.router.url.toString();

        /* console.log('event', typeof event, event);
        console.log('current route: ', this.router.url.toString()); */
      }
    });
  }
  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    /* initBacktoTop */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $(".back-to-top").fadeIn();
      } else {
        $(".back-to-top").fadeOut();
      }
    });
    $(".back-to-top").click(function () {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
      return false;
    });

    /* initSmoothLink */
    $(".smooth-link").on("click", function (event) {
      event.preventDefault();

      var $anchor = $(this);
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $($anchor.attr("href")).offset().top - 100,
          },
          1500,
          "easeInOutExpo"
        );
      event.preventDefault();
    });

    $(".navbar-nav a").on("click", function (event) {
      var $anchor = $(this);
      $("html, body")
        .stop()
        .animate(
          {
            scrollTop: $($anchor.attr("href")).offset().top - 50,
          },
          1500,
          "easeInOutExpo"
        );
      event.preventDefault();
    });

    /* initMobileMenu */
    const netflix_open_btn = document.querySelector(".netflix-open-btn");
    const netflix_close_btn = document.querySelector(".netflix-close-btn");
    const netflix_nav = document.querySelectorAll(".netflix-nav");

    if (netflix_open_btn == null) return;
    netflix_open_btn.addEventListener("click", () => {
      $(".mobile-top-menu").css("z-index", "0");
      netflix_nav.forEach((nav_el) => {
        nav_el.classList.add("visible");
      });
    });

    netflix_close_btn.addEventListener("click", () => {
      $(".mobile-top-menu").css("z-index", "100000000000");
      netflix_nav.forEach((nav_el) => {
        nav_el.classList.remove("visible");
      });
    });

    /* initStickyMenu */
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 170) {
        $(".menu-header").css("position", "fixed");
      } else {
        $(".menu-header").css("position", "relative");
      }
    });

  }
}
