import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '../../../components/shared/animations';
import { TypeElements, Elements } from '../../../interfaces';
import { BdService } from '../../../services/bd.service';
import { UserSession } from '../../../services/user-session.service';
import { UtilsService } from '../../../services/utils.service';
declare var $: any

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  animations: [fuseAnimations],
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  service_une_phrase_type: TypeElements;
  service_une_phrase_elements: Elements[] = [];
  qui_sommes_nous_type: TypeElements;
  stats_type: TypeElements;
  stats_elements: Elements[] = [];
  pourquoi_nous_choisir_type: TypeElements;
  services_type: TypeElements;
  services_elements: Elements[] = [];
  acceuil_blog_type: TypeElements;
  testimonials_type: TypeElements;
  testimonials_elements: Elements[] = [];
  maxArticles = 6;
  statsCounterDelay = 2;
  statPopulated: boolean;
  servicesCarouselPopulated: any;
  articles: any[] = [];
  articlesPopulated: any;
  testimonialsPopulated: boolean;
  qui_sommes_nous_menu: TypeElements;
  acceuil_menu: any;
  partnersPopulated: boolean;
  partners_type: TypeElements;
  partners_elements: Elements[] = [];
  teamCarouselPopulated: boolean;
  teams_type: TypeElements;
  teams_elements: Elements[] = [];
  qui_sommes_nous_hero: TypeElements;

  constructor(public userSession: UserSession,
    public route: ActivatedRoute,
    public utils_service: UtilsService,
    public bd_service: BdService) { }

  ngOnInit(): void {
    //trigger get data to actuialise th data in background
    //this.bd_service.get_data();
    //but continue with availabe data
    if (this.bd_service.public_types_elements.length == 0 || this.bd_service.public_elements.length == 0) {
      this.bd_service.public_observable.subscribe(data => {
        //console.log('data updated !');
        this.prepare_data();
      });
    } else {
      this.prepare_data();
    }

  }

  prepare_data() {

    //service une phrase
    this.service_une_phrase_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_SERVICES_EN_UNE_PHRASE, this.bd_service.public_types_elements);
    this.service_une_phrase_elements = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.SECTION_SERVICES_EN_UNE_PHRASE, this.bd_service.public_elements);
    switch (this.service_une_phrase_type.contenu.one_phrase_service_order_by) {
      case "Plus récents d'abord":
        this.service_une_phrase_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        break;
      case "Plus anciens d'abord":
        this.service_une_phrase_elements.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        break;
      case "Priorité personnalisée":
        this.service_une_phrase_elements.sort((a, b) => a.contenu.one_phrase_service_element_priority - b.contenu.one_phrase_service_element_priority);
        break;

      default:
        this.service_une_phrase_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        break;
    }

    //qui sommes nous
    this.qui_sommes_nous_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_QUI_SOMMES_NOUS, this.bd_service.public_types_elements);
    this.qui_sommes_nous_hero = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.QUI_SOMMES_NOUS_SECTION_DACCUEIL, this.bd_service.public_types_elements);
    //console.log('qui_sommes_nous_menu', this.qui_sommes_nous_menu);


    //stats
    if (!this.statPopulated) {
      this.stats_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_STATISTIQUES_COMPTEURS, this.bd_service.public_types_elements);
      const statsCounterDelay = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_STATISTIQUES_COMPTEURS, this.bd_service.public_types_elements)?.contenu?.stat_counter_duration;
      if (statsCounterDelay != undefined && statsCounterDelay != null && statsCounterDelay != '') this.statsCounterDelay = statsCounterDelay * 1000;
      this.stats_elements = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.SECTION_STATISTIQUES_COMPTEURS, this.bd_service.public_elements);
      switch (this.stats_type.contenu.stat_counter_order_by) {
        case "Plus récents d'abord":
          this.stats_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
        case "Plus anciens d'abord":
          this.stats_elements.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
          break;
        case "Priorité personnalisée":
          this.stats_elements.sort((a, b) => a.contenu.stat_counter_element_priority - b.contenu.stat_counter_element_priority);
          break;

        default:
          this.stats_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
      }
    }

    //partners
    if (!this.partnersPopulated) {
      this.partners_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_NOS_PARTENAIRES, this.bd_service.public_types_elements);
      this.partners_elements = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.SECTION_NOS_PARTENAIRES, this.bd_service.public_elements);
      switch (this.partners_type.contenu.partners_order_by) {
        case "Plus récents d'abord":
          this.partners_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
        case "Plus anciens d'abord":
          this.partners_elements.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
          break;
        case "Priorité personnalisée":
          this.partners_elements.sort((a, b) => a.contenu.partner_element_priority - b.contenu.partner_element_priority);
          break;

        default:
          this.partners_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
      }
    }

    //team
    if (!this.teamCarouselPopulated) {
      this.teams_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.SECTION_NOTRE_EQUIPE, this.bd_service.public_types_elements);
      this.teams_elements = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.SECTION_NOTRE_EQUIPE, this.bd_service.public_elements);
      switch (this.teams_type.contenu.teams_carousel_order_by) {
        case "Plus récents d'abord":
          this.teams_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
        case "Plus anciens d'abord":
          this.teams_elements.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
          break;
        case "Priorité personnalisée":
          this.teams_elements.sort((a, b) => a.contenu.teams_member_element_priority - b.contenu.teams_member_element_priority);
          break;

        default:
          this.teams_elements.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          break;
      }
    }

    const menus = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.ELEMENTS_DU_MENU, this.bd_service.public_elements);
    this.qui_sommes_nous_menu = menus.find(m => m.contenu?.menu_item_element_page_link_link_obj?.slug == "qui-sommes-nous");
    this.acceuil_menu = menus.find(m => m.contenu?.menu_item_element_page_link_link_obj?.slug == "acceuil");

  }

  initTeamCarousel() {
    setTimeout(() => {
      if (!this.teamCarouselPopulated) {
        //recover the carousel class
        var owl = $(".team-carousel-container").children(".owl-carousel");
        owl.owlCarousel({
          autoplay: true,
          nav: false,
          items: 4,
          dots: true,
          loop: true,
          center: true,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 1,
            },
            1000: {
              items: 3,
            },
            1400: {
              items: 4,
            },
          },
        });
        this.teamCarouselPopulated = true;
      }
    }, 30);
  }

  initParnerCarousel() {
    setTimeout(() => {
      if (!this.partnersPopulated) {
        //recover the carousel class
        var owl = $(".partenaires-carousel-container").children(".owl-carousel");
        owl.owlCarousel({
          autoplay: true,
          nav: false,
          items: 3,
          dots: true,
          loop: true,
          center: true,
          responsiveClass: true,
          responsive: {
            0: {
              items: 2,
            },
            600: {
              items: 2,
            },
            1000: {
              items: 4,
            },
            1400: {
              items: 4,
            },
          },
        });
        this.partnersPopulated = true;
      }
    }, 300);
  }

  initStatCount() {
    if (!this.statPopulated) {
      setTimeout(() => {

        $(".stats-digits").on("scrollSpy:enter", function () {
          //console.log('to init stat count');
          $(".countfect").each(function () {
            var $this = $(this);
            var countTo = $this.attr("data-num");
            var delayTo = +$this.attr("delay");
            if (!delayTo) {
              delayTo = 8000;
            }
            $({
              countNum: $this.text(),
            }).animate(
              {
                countNum: countTo,
              },
              {
                duration: delayTo,
                easing: "linear",
                step: function () {
                  $this.text(Math.floor(this.countNum));
                },
                complete: function () {
                  $this.text(this.countNum);
                },
              }
            );
          });
        });
        $(".stats-digits").scrollSpy();
        this.statPopulated = true;
      }, 300);
    }
  }


}
