import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Elements, TypeElements } from '../../../interfaces';
import { BdService } from '../../../services/bd.service';
import { UserSession } from '../../../services/user-session.service';
import { UtilsService } from '../../../services/utils.service';
import { fuseAnimations} from '../../../components/shared/animations';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  animations: [fuseAnimations],
styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  footer_type: TypeElements;
  elements_du_menu_type: TypeElements;

  constructor(public userSession: UserSession,
    public route: ActivatedRoute,
    public utils_service: UtilsService,
    public bd_service: BdService) { }

  ngOnInit(): void {

    //trigger get data to actuialise th data in background
    //this.bd_service.get_data();
    //but continue with availabe data
    if (this.bd_service.public_types_elements.length == 0 || this.bd_service.public_elements.length == 0) {
      this.bd_service.public_observable.subscribe(data => {
        //console.log('data updated !');
        this.prepare_data();
      });
    } else {
      this.prepare_data();
    }


  }

  prepare_data() {
    this.footer_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.PIED_DE_PAG, this.bd_service.public_types_elements);
    //console.log('footer_type', this.footer_type);

    this.elements_du_menu_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.ELEMENTS_DU_MENU, this.bd_service.public_types_elements);
    this.elements_du_menu_type.contenu.children = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.ELEMENTS_DU_MENU, this.bd_service.public_elements);
    this.elements_du_menu_type.contenu.children = this.elements_du_menu_type.contenu.children.sort((a, b) => a.contenu.menu_item_element_priority - b.contenu.menu_item_element_priority);
    //console.log('get_href_link_value', this.bd_service.get_href_link_value(this.footer_type, 'footer_socials_2_link'));

    /* this.elements_du_menu_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.ELEMENTS_DU_MENU, this.bd_service.public_types_elements);
    this.elements_du_menu_type.contenu.children = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.ELEMENTS_DU_MENU, this.bd_service.public_elements); */
  }

}
