<app-header></app-header>
<!-- hero section -->
<section [@slideInLeft] [@slideInRight] class="d-flex flex-column align-items-center w-100 p-5 standard-section-back">
  <div class="section-contentd-flex flex-row justify-content-center align-items-center position-relative p-5 mt-4">
    <div class="breadcrumbs d-flex flex-column mx-auto align-items-center mb-3 p-4">
      <h1 class="xlarge center title bold mb-1 gray2 mt-auto mb-3">Oups! 404</h1>
      <p class="title medium center bold gray2 mb-1">Page non trouvée</p>
      <p class="light small center gray2 mb-3">La page que vous essayez d’afficher n’existe pas ou a été déplacée. <br> Voici ce que vous pouvez faire :</p>
      <form class="d-flex flex-column flex-lg-row align-items-center position-relative justify-content-center" action="./recherche.html">
        <!-- ← Retour à l’accueil -->
        <a [routerLink]="['/']" class="cta cta-primary mr-0 mr-lg-4">← Retour à l’accueil</a>
        <!-- recherche -->
        <app-search label="no"></app-search>
      </form>
    </div>
  </div>
</section>

<app-footer></app-footer>
