<!-- articles -->
<div class="articles d-flex flex-row align-items-stretch justify-content-center flex-wrap">
  <!-- article -->
  <div [@fadeInBottom] [@fadeOutTop] class="article d-flex flex-column align-items-start m-3 hover-to-accent-border" *ngFor="let article of articles; let i = index; let l = last">
    {{ l === true ? setArticlesPopulated() : null}}
    <!-- image head *
      categories
      contenu
      created_at
      editeur
      id
      media
      media_id
      slug
      sous_titre
      tag
      titre
      updated_at
      -->
    <ng-container *ngIf="simpleDisplayIds.includes(1); else normalDisplay">
      <!-- article text -->
      <div class="article text-article d-flex flex-column align-items-start m-3">
        <!-- article-content -->
        <div class="article-content d-flex flex-column align-items-start p-4">
          <a [appRemoveOnNull]="article.titre" [routerLink]="['/blog/article/' + article.slug]" class="article-title hover-to-accent black medium title bold mb-2">
            {{article.titre}}
          </a>
          <p class="category title small bold left mb-2" [appRemoveOnNull]="article.categories">
            <a class="primary hover-to-accent" [routerLink]="['/blog/categorie/' + categorie.slug]" *ngFor="let categorie of article.categories">• {{categorie.nom}}</a>
            <span class="date gray2">• {{article.created_at | date:'dd MMM yyyy'}}</span>
          </p>
          <p class="exerpt small left" [appRemoveOnNull]="article.contenu">
            <span class="regular gray2" [innerHTML]="get_article_exerpt(article) | sanitizeHtml"></span>
            <a [routerLink]="['/blog/article/' + article.slug]" class="primary bold hover-to-accent">{{read_more != undefined ? read_more : 'Lire Plus →'}}</a>
          </p>
        </div>
      </div>
    </ng-container>
    <ng-template #normalDisplay>

      <div class="image-head d-flex flex-row align-items-center position-relative w-100 overflow-hidden">
        <!-- article date -->
        <div class="article-date primary-back d-flex flex-column align-items-center position-absolute top-0 left-0 p-2" [appRemoveOnNull]="article.created_at">
          <p class="title large bold white day mb-0">{{article.created_at | date:'dd'}}</p>
          <p class="title small bold white month-year mb-0">{{article.created_at | date:'MMM yyyy'}}</p>
        </div>
        <img [src]="article.media?.path | imgUrl" [appRemoveOnNull]="article.media?.path" alt="" class="">
      </div>
      <!-- article-content -->
      <div class="article-content d-flex flex-column align-items-start px-4 pt-3 pb-2">
        <p class="category title small bold left primary mb-2" [appRemoveOnNull]="article.categories">
          <a class="primary hover-to-accent mr-2" [routerLink]="['/blog/categorie/' + categorie.slug]" *ngFor="let categorie of article.categories">• {{categorie.nom}}</a>
        </p>
        <a [appRemoveOnNull]="article.titre" [routerLink]="['/blog/article/' + article.slug]" class="article-title hover-to-accent gray2 medium title bold mb2">
          {{article.titre}}
        </a>
        <p class="exerpt small left" [appRemoveOnNull]="article.contenu">
          <span class="regular gray2" [innerHTML]="get_article_exerpt(article) | sanitizeHtml"></span>
          <a [routerLink]="['/blog/article/' + article.slug]" class="primary bold hover-to-accent">{{read_more != undefined ? read_more : 'Lire Plus →'}}</a>
        </p>
      </div>
    </ng-template>
  </div>

</div>
