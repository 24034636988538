import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'montantify'
})
export class MontantifyPipe implements PipeTransform {


  transform(montant: unknown, ...args: unknown[]): string {
    let montant_string = "" + montant;
    if (montant_string.length == 4) {
      montant_string =
        montant_string.substr(0, 1) + "." + montant_string.substr(1, 3);
    } else if (montant_string.length == 5) {
      montant_string =
        montant_string.substr(0, 2) + "." + montant_string.substr(2, 3);
    } else if (montant_string.length == 6) {
      montant_string =
        montant_string.substr(0, 3) + "." + montant_string.substr(3, 3);
    } else if (montant_string.length == 7) {
      montant_string =
        montant_string.substr(0, 1) +
        "." +
        montant_string.substr(1, 3) +
        "." +
        montant_string.substr(4, 3);
    } else if (montant_string.length == 8) {
      montant_string =
        montant_string.substr(0, 2) +
        "." +
        montant_string.substr(2, 3) +
        "." +
        montant_string.substr(5, 3);
    }
    return montant_string;
  }

}
