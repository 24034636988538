import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Categories, TypeElements } from '../../../interfaces';
import { BdService } from '../../../services/bd.service';
import { UserSession } from '../../../services/user-session.service';
import { UtilsService } from '../../../services/utils.service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  blog_sidebar_type: TypeElements;
  categories: Categories[] = [];


  @ViewChild('videoSidebar')
  set watch(videoSidebar: ElementRef) {
    if (videoSidebar) {
      this.initVideoPopup();
    }
  }



  constructor(public userSession: UserSession,
    public route: ActivatedRoute,
    public router: Router,
    public utils_service: UtilsService,
    public bd_service: BdService) { }

  ngOnInit(): void {
    console.log('itialized sidebar');

    //trigger get data to actuialise th data in background
    //this.bd_service.get_data();
    //but continue with availabe data
    if (this.bd_service.public_types_elements.length == 0 || this.bd_service.public_elements.length == 0) {
      this.bd_service.public_observable.subscribe(data => {
        //console.log('data updated !');
        this.prepare_data();
      });
    } else {
      this.prepare_data();
    }

    this.bd_service.getArrayData(
      this.bd_service.get_categories(),
      (data_array: any[], message: any,) => {
        this.categories = data_array;
      });

  }


  prepare_data() {

    this.blog_sidebar_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.BLOG_VIDEO_COLONNE_DROITE, this.bd_service.public_types_elements);

  }

  initVideoPopup() {
    $(".video-placeholder").magnificPopup({
      disableOn: 700,
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });
  }

}
