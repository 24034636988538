 <!-- hero section -->
 <section [@slideInLeft] [@slideInRight]  class="d-flex flex-column align-items-center w-100 p-5 standard-section-back">
        <div class="section-contentd-flex flex-row justify-content-center align-items-center position-relative mt-4">
            <div class="breadcrumbs d-flex flex-column mx-auto align-items-center mb-3">
                <h1 class="xlarge center title bold mb-1 gray2 mt-auto mb-3">Titre page simple</h1>
                <p class="title small center bold gray2 mb-auto"><a href="./index.html" class="primary hover-to-opacity">Accueil</a> • <span class="gray2 light">Titre page simple</span></p>
            </div>
        </div>
    </section>

    <!-- article section -->
    <section [@slideInLeft] [@slideInRight]  class="article-section d-flex flex-row align-items-center justify-content-center white-back w-100 mt-0 pt-5 mb-3">

        <div class="section-content d-flex flex-column flex-lg-row align-items-lg-start justify-content-center align-items-center mb-4">

            <!-- article content -->
            <div class="article-content d-flex flex-row align-items-stretch justify-content-center flex-wrap w-60 pr-lg-5 mr-0 mr-lg-5">
                <!-- main-image -->
                <img src="./images/main-image.jpg" alt="" class="w-100 main-image mb-3">
                <div class="article-body dfle flex-column align-items-start">
                    <h2 class="title medium left bold">Titre de niveau 2</h2>
                    <p class="small regular gray2 left">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea </p>
                    <h2 class="title medium left bold">Titre de niveau 2</h2>
                    <p class="small regular gray2 left">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea </p>
                </div>
                <!-- gallery -->
                <div class="gallery owl-carousel owl-theme w-100 d-flex flex-column mt-2">
                    <!-- image -->
                    <a href="./images/blog-standard-1.jpg" class="mx-2" title="Blog Standard 1">
                        <img src="./images/blog-standard-1.jpg">
                    </a>
                    <!-- image -->
                    <a href="./images/blog-standard-2.jpg" class="mx-2" title="Blog Standard 2">
                        <img src="./images/blog-standard-2.jpg">
                    </a>
                    <!-- image -->
                    <a href="./images/blog-standard-3.jpg" class="mx-2" title="Blog Standard 3">
                        <img src="./images/blog-standard-3.jpg">
                    </a>
                    <!-- image -->
                    <a href="./images/blog-standard-4.jpg" class="mx-2" title="Blog Standard 4">
                        <img src="./images/blog-standard-4.jpg">
                    </a>
                </div>
            </div>
            <!-- sidebar -->
            <div class="sidebar d-flex flex-column align-items-start ml-0 ml-lg-5 mt-4 mt-lg-0 w-30">
                <!-- recherche -->
                <div class="recherche d-flex flex-column align-items-start">
                    <p class="title medium gray2 bold left mb-2 w-100">Services et Prestations </p>
                    <div class="d-flex flex-column align-items-start mt-1">
                        <!-- Médicale -->
                        <a href="./simple.html" class="d-flex flex-row gray2 hover-to-accent bold my-2 align-items-center">
                            <img src="./images/SVG/check-green.svg" alt="" class="h-2em mr-2">
                            <span class="small regular">Prise en charge médicale</span>
                        </a>
                        <!-- Para médicale -->
                        <a href="./simple.html" class="d-flex flex-row gray2 hover-to-accent bold my-2 align-items-center">
                            <img src="./images/SVG/check-green.svg" alt="" class="h-2em mr-2">
                            <span class="small regular">Prise en charge paramédicale</span>
                        </a>
                        <!-- Médicale -->
                        <a href="./simple.html" class="d-flex flex-row gray2 hover-to-accent bold my-2 align-items-center">
                            <img src="./images/SVG/check-green.svg" alt="" class="h-2em mr-2">
                            <span class="small regular">Transport médical</span>
                        </a>
                        <!-- Para médicale -->
                        <a href="./simple.html" class="d-flex flex-row gray2 hover-to-accent bold my-2 align-items-center">
                            <img src="./images/SVG/check-green.svg" alt="" class="h-2em mr-2">
                            <span class="small regular">Formation et stage</span>
                        </a>
                    </div>
                </div>
                <!-- decouvrez -->
                <div class="decouvrez d-flex flex-column align-items-start w-100 mt-5">
                    <p class="title medium gray2 bold left mb-3 w-100"><span class="">Découvrez ILEIRA</span></p>
                    <div class="video-anchor d-flex flex-row align-items-center w-80">
                        <a href="https://www.youtube.com/watch?v=1ZYbU82GVz4" class="video-placeholder h-100 w-100 position-relative">
                            <img src="./images/video-placeholder.jpg" alt="" class="placeholder position-relative">
                            <div class="d-flex flex-row align-items-center video-btn position-absolute">
                                <img src="./images/SVG/big-play-button.svg" alt="" class="mx-auto h-2-em">
                            </div>
                        </a>
                    </div>
                </div>
                <!-- actualirés récentes -->
                <div class="actualites d-flex flex-column align-items-start w-100 mt-5">
                    <p class="title medium gray2 bold left mt-2 mb-3 w-100"><span class="">Actualités Récentes</span></p>
                    <div class="articles d-flex flex-column align-items-stretch w-100">
                        <!-- recent-article -->
                        <div class="article recent-article d-flex flex-row align-items-stretch my-2">
                            <!-- image -->
                            <img src="./images/blog-standard-2.jpg" alt="" class="recent-image">
                            <!-- article-content -->
                            <div class="article-content d-flex flex-column align-items-start ml-2">
                                <p class="category title xsmall bold left mb-1 mt-2"><a class="primary hover-to-accent" href="./categorie.html">Rubrique de l’article</a> <span class="date gray4">• 01 Jan 2023</span></p>
                                <a href="./article-detail.html" class="article-title hover-to-accent gray2 xsmall title bold mb-2">
                                    Lorem Lorem ipsum dolor sit amet, consectetuer
                                </a>

                            </div>
                        </div>
                        <!-- recent-article -->
                        <div class="article recent-article d-flex flex-row align-items-stretch my-2">
                            <!-- image -->
                            <img src="./images/blog-standard-2.jpg" alt="" class="recent-image">
                            <!-- article-content -->
                            <div class="article-content d-flex flex-column align-items-start ml-2">
                                <p class="category title xsmall bold left mb-1 mt-2"><a class="primary hover-to-accent" href="./categorie.html">Rubrique de l’article</a> <span class="date gray4">• 01 Jan 2023</span></p>
                                <a href="./article-detail.html" class="article-title hover-to-accent gray2 xsmall title bold mb-2">
                                    Lorem Lorem ipsum dolor sit amet, consectetuer
                                </a>

                            </div>
                        </div>
                        <!-- recent-article -->
                        <div class="article recent-article d-flex flex-row align-items-stretch my-2">
                            <!-- image -->
                            <img src="./images/blog-standard-2.jpg" alt="" class="recent-image">
                            <!-- article-content -->
                            <div class="article-content d-flex flex-column align-items-start ml-2">
                                <p class="category title xsmall bold left mb-1 mt-2"><a class="primary hover-to-accent" href="./categorie.html">Rubrique de l’article</a> <span class="date gray4">• 01 Jan 2023</span></p>
                                <a href="./article-detail.html" class="article-title hover-to-accent gray2 xsmall title bold mb-2">
                                    Lorem Lorem ipsum dolor sit amet, consectetuer
                                </a>

                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </section>

    <!-- stats section -->
    <section [@slideInLeft] [@slideInRight]  class="stats-section d-flex flex-row align-items-center justify-content-center w-100 mb-0 mt-7 stats-back p-4">
        <div class="section-content d-flex flex-column flex-lg-row justify-content-around align-items-center">
            <!-- stat -->
            <div class="stat d-flex flex-column align-items-start">
                <img src="./images/SVG/annees.svg" alt="" class="h-3em">
                <!-- counter -->
                <p class="stats-digits title bold font-45-em white left mb-0 mt-3"><span delay="5000" data-num="10" class="countfect annees-counter"></span>+</p>
                <p class="small bold white left">Années <br>d'expérience</p>
            </div>
            <!-- stat -->
            <div class="stat d-flex flex-column align-items-start ml-3">
                <img src="./images/SVG/abonnes.svg" alt="" class="h-3em">
                <!-- counter -->
                <p class="stats-digits title bold font-45-em white left mb-0 mt-3"><span delay="5300" data-num="5800" class="countfect abonnes-counter"></span>+</p>
                <p class="small bold white left">Abonnés & <br>membres</p>
            </div>
            <!-- stat -->
            <div class="stat d-flex flex-column align-items-start ml-3">
                <img src="./images/SVG/partenaires.svg" alt="" class="h-3em">
                <!-- counter -->
                <p class="stats-digits title bold font-45-em white left mb-0 mt-3"><span delay="5000" data-num="120" class="countfect partenaires-counter"></span>+</p>
                <p class="small bold white left">Partenaires de <br>par le monde</p>
            </div>
            <!-- stat -->
            <div class="stat d-flex flex-column align-items-start ml-3">
                <img src="./images/SVG/pays.svg" alt="" class="h-3em">
                <!-- counter -->
                <p class="stats-digits title bold font-45-em white left mb-0 mt-3"><span delay="5500" data-num="30" class="countfect pays-counter"> </span>+</p>
                <p class="small bold white left">Pays et localités <br> touchés</p>
            </div>
        </div>

    </section>

    <!-- nos produits section -->
    <section [@slideInLeft] [@slideInRight]  class="nos-produits-section d-flex flex-column align-items-center w-100 mb-5 mt-0 white-back p-5">
        <div class="section-content head d-flex flex-column align-items-center produits-carousel-container position-relative">
            <!-- floating logo -->
            <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="particlemove2 floating-logo position-absolute">
            <!-- texts -->
            <div class="texts d-flex flex-column align-items-start w-40 mr-auto mb-5">
                <p class="primary medium bold left mb-1">CE QUE NOUS OFFRONS</p>
                <p class="title font-3-em bold gray2 left mb-2">Nos Produits et Services</p>
                <p class="gray2 medium light left mt-2">Avec ILEIRA, je prends rapidement en charge et à tout moment la santé de ma famille restée au pays.</p>
            </div>
            <!-- features -->
            <div class="features d-flex flex-row align-items-stretch will-be-owl-carousel position-relative mt2">
                <!-- feature -->
                <div class="feature white-back features-shadow d-flex flex-column align-items-center p-4 mx-2">
                    <img src="./images/SVG/traitement-medical.svg" alt="" class="feature-ill mb-2">
                    <p class="gray2 title bold medium center">Traitement Medical</p>
                    <p class="small regular gray2 center mb-2">Prise en charge rapide et professionnelle par nos cliniques partenaires selon leur localisation et leur plateau technique.</p>
                    <a href="./simple.html" class="primary hover-to-accent bold mb-3">Lire Plus →</a>
                </div>
                <!-- feature -->
                <div class="feature white-back features-shadow d-flex flex-column align-items-center p-4 mx-2">
                    <img src="./images/SVG/para-medical.svg" alt="" class="feature-ill mb-2">
                    <p class="gray2 title bold medium center">Para-medical</p>
                    <p class="small regular gray2 center mb-2">Large disponibilité des produits pharmaceutiques pour vos proches et familles grâce aux Pharmacies partenaires de ILEIRA.</p>
                    <a href="./simple.html" class="primary hover-to-accent bold mb-3">Lire Plus →</a>
                </div>
                <!-- feature -->
                <div class="feature white-back features-shadow d-flex flex-column align-items-center p-4 mx-2">
                    <img src="./images/SVG/transport-medical.svg" alt="" class="feature-ill mb-2">
                    <p class="gray2 title bold medium center">Transport Médicalisé</p>
                    <p class="small regular gray2 center mb-2">ILEIRA propose un secteur de transport pour vos parents, vers et depuis une structure hospitalière pour des soins et des examens.</p>
                    <a href="./simple.html" class="primary hover-to-accent bold mb-3">Lire Plus →</a>
                </div>
                <!-- feature -->
                <div class="feature white-back features-shadow d-flex flex-column align-items-center p-4 mx-2">
                    <img src="./images/SVG/formation-stage.svg" alt="" class="feature-ill mb-2">
                    <p class="gray2 title bold medium center">Formation & Stage</p>
                    <p class="small regular gray2 center mb-2">ILEIRA est aussi une plate-forme de formation et conseils pour les différentes catégories de professionnels.</p>
                    <a href="./simple.html" class="primary hover-to-accent bold mb-3">Lire Plus →</a>
                </div>
            </div>
            <!-- Devenir Membre Maintenant → -->
            <a href="#" class="cta cta-primary mr-4 d-flex mt-6 mb-3">Devenir Membre Maintenant →</a>
        </div>
    </section>
