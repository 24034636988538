/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable curly */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable arrow-parens */


import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Elements, TypeElements } from '../../../interfaces';
import { BdService } from '../../../services/bd.service';
import { UserSession } from '../../../services/user-session.service';
import { UtilsService } from '../../../services/utils.service';
import { fuseAnimations } from '../../../components/shared/animations';
import { visitAll } from '@angular/compiler';
import * as bootstrap from "bootstrap";

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  animations: [fuseAnimations],
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  not_contact_elements_for_header: Elements[] = [];
  contact_element: Elements;
  espace_membre_element: Elements;
  entete_type: TypeElements;
  elements_du_menu_type: TypeElements;
  user_popover1: any;
  user_popover2: any;

  @ViewChild('mobileMenu')
  set watch(mobileMenu: ElementRef) {
    if (mobileMenu) {
      this.initMobileMenu();
    }
  }
  @ViewChild('user_popover2')
  set watch2(userPop2: ElementRef) {
    if (userPop2) {
      this.initUserPopover();
    }
  }
  @ViewChild('user_popover1')
  set watch1(userPop1: ElementRef) {
    if (userPop1) {
      this.initUserPopover();
    }
  }
  already_prepared = false;

  constructor(
    public userSession: UserSession,
    public route: ActivatedRoute,
    public utils_service: UtilsService,
    public bd_service: BdService
  ) {

  }

  ngOnInit(): void {
    //trigger get data to actuialise th data in background
    //this.bd_service.get_data();
    //but continue with availabe data
    if (this.bd_service.public_types_elements.length == 0 || this.bd_service.public_elements.length == 0) {
      this.bd_service.public_observable.subscribe(data => {
        //console.log('data updated !');
        this.prepare_data();
      });
    } else {
      this.prepare_data();
    }
  }

  prepare_data() {
    this.entete_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.ENTETE, this.bd_service.public_types_elements);
    this.elements_du_menu_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.ELEMENTS_DU_MENU, this.bd_service.public_types_elements);
    this.elements_du_menu_type.contenu.children = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.ELEMENTS_DU_MENU, this.bd_service.public_elements);
    this.elements_du_menu_type.contenu.children = this.elements_du_menu_type.contenu.children.sort((a, b) => a.contenu.menu_item_element_priority - b.contenu.menu_item_element_priority);

    this.contact_element = this.elements_du_menu_type.contenu.children[6];
    this.espace_membre_element = this.elements_du_menu_type.contenu.children[5];
    this.not_contact_elements_for_header = this.elements_du_menu_type.contenu.children.filter(element => element.id != this.contact_element.id && element.id != this.espace_membre_element.id);

    //console.log('not_contact_elements_for_header', this.not_contact_elements_for_header);

  }
  verify_scroll() {
    setTimeout(() => {

      var scroll = $(window).scrollTop();
      if (scroll >= 140) {
        $(".menu-header").css("position", "fixed");
        $("header").css("transform", "none");
      } else {
        $(".menu-header").css("position", "relative");
      }
      //console.log('verifiyin', scroll, scroll >= 170);
    }, 500);
  }

  initMobileMenu() {
    /* initMobileMenu */
    const netflix_open_btn = document.querySelector(".netflix-open-btn");
    const netflix_close_btn = document.querySelector(".netflix-close-btn");
    const netflix_nav = document.querySelectorAll(".netflix-nav");

    if (netflix_open_btn == null) return;
    netflix_open_btn.addEventListener("click", () => {

      $(".mobile-top-menu").css("z-index", "0");
      netflix_nav.forEach((nav_el) => {
        nav_el.classList.add("visible");
      });
    });

    netflix_close_btn.addEventListener("click", () => {
      $(".mobile-top-menu").css("z-index", "100000000000");
      netflix_nav.forEach((nav_el) => {
        nav_el.classList.remove("visible");
      });
    });
    //console.log('initinig menu mobile', netflix_open_btn);

  }
  closeMenuMobile() {
    const netflix_nav = document.querySelectorAll(".netflix-nav");
    $(".mobile-top-menu").css("z-index", "100000000000");
    netflix_nav.forEach((nav_el) => {
      nav_el.classList.remove("visible");
    });
  }
  openMobileMenu() {
    const netflix_nav = document.querySelectorAll(".netflix-nav");
    $(".mobile-top-menu").css("z-index", "0");
    netflix_nav.forEach((nav_el) => {
      nav_el.classList.add("visible");
    });
    //console.log('mobile menu click');
  }
  popover1_click() {
    //console.log('popover clicked', $("#user_popover1").length, this.user_popover1);
    //this.user_popover1.toggle();
  }
  popover2_click() {
    //console.log('popover clicked', $("#user_popover2").length, $("#user_popover2"));
    //this.user_popover2.toggle();
  }
  initUserPopover() {
    const self = this;
    //if(this.already_prepared) return;

    // Enables popover
    const _user_popover1 = document.querySelector("#user_popover1");

    if (_user_popover1 == null) return;
    this.user_popover1 = new bootstrap.Popover(_user_popover1, {
      html: true,
      placement: "bottom",
      trigger: 'focus',
      sanitize: false,
      content: function () {
        var content = $(this).attr("data-popover-template");
        return $(content).children(".popover-body").html();
      },
      title: function () {
        var title = $(this).attr("data-popover-template");
        return $(title).children(".popover-heading").html();
      }
    });
    console.log('this.user_popover1', this.user_popover1);
    console.log('this.user_popover1', this.user_popover1?.tip);
    _user_popover1.addEventListener('shown.bs.popover', function () { self.register_popover_clicks(); });

    /*  const _user_popover2 = document.querySelector("#user_popover2");
     this.user_popover2 = new bootstrap.Popover(_user_popover2, {
       html: true,
       placement: "bottom",
       trigger: 'focus',
       sanitize: false,
       content: function () {
         var content = $(this).attr("data-popover-template");
         return $(content).children(".popover-body").html();
       },
       title: function () {
         var title = $(this).attr("data-popover-template");
         return $(title).children(".popover-heading").html();
       }
     });
     _user_popover2.addEventListener('shown.bs.popover', function () { self.register_popover_clicks(); }); */
    this.already_prepared = true;
    if (this.userSession.getUser().id) {
    } else {
      this.userSession.setUser(null);
      this.userSession.setToken(null);
      this.logout();
    }

  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //console.log('user_popover1', this.user_popover1);

    this.user_popover1?.dispose();
    this.user_popover2?.dispose();
  }

  register_popover_clicks() {
    const self = this;
    $(".go_to_dashboard_notifs").click(function (e) {
      e.preventDefault(); self.go_to_dashboard_notifs();
    });
    $(".go_to_dashboard_profile").click(function (e) {
      e.preventDefault(); self.go_to_dashboard_profile();
    });
    $(".proceed_to_mail_verify").click(function (e) {
      e.preventDefault(); self.proceed_to_mail_verify();
    });
    $(".proceed_to_phone_verify").click(function (e) {
      e.preventDefault(); self.proceed_to_phone_verify();
    });
    $(".go_to_dashboard").click(function (e) {
      e.preventDefault(); self.go_to_dashboard();
    });
    $(".logout").click(function (e) {
      e.preventDefault(); self.logout();
    });
  }
  go_to_dashboard_notifs() {
    window.open(this.bd_service.USER_DASHBOARD_SERVER + '/user-profile?token=' + this.userSession.token, '_self');
  }
  go_to_dashboard_profile() {
    window.open(this.bd_service.USER_DASHBOARD_SERVER + '/user-profile?token=' + this.userSession.token, '_self');
  }
  proceed_to_mail_verify() {
    if (this.userSession.has_verified_mail()) return;
    window.open(this.bd_service.USER_DASHBOARD_SERVER + '/user-profile?target=mail_verif&token=' + this.userSession.token, '_self');
  }
  proceed_to_phone_verify() {
    if (this.userSession.has_verified_phone()) return;
    window.open(this.bd_service.USER_DASHBOARD_SERVER + '/user-profile?target=phone_verif&token=' + this.userSession.token, '_self');

  }
  go_to_dashboard() {
    window.open(this.bd_service.USER_DASHBOARD_SERVER + '?token=' + this.userSession.token, '_self');
  }
  
  logout() {
    console.log('disconected', localStorage.getItem(this.userSession.TOKEN_STORE_KEY));
    console.log('disconected', localStorage.getItem(this.userSession.TOKEN_EXP_STORE_KEY));
    this.userSession.token = null;
    this.userSession.user = undefined;
    localStorage.removeItem(this.userSession.TOKEN_STORE_KEY);
    localStorage.removeItem(this.userSession.TOKEN_EXP_STORE_KEY);
    this.user_popover1?.dispose();
    this.user_popover2?.dispose();
  }

  ngAfterViewInit(): void {


    /* initStickyMenu */
    let self = this;
    $(window).scroll(function () {
      self.verify_scroll();
    });
    self.verify_scroll();
  }

}
