import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { constructor } from 'lodash';
import { fuseAnimations } from '../../../components/shared/animations';
import { Faqs, TypeElements } from '../../../interfaces';
import { BdService } from '../../../services/bd.service';
import { UserSession } from '../../../services/user-session.service';
import { UtilsService } from '../../../services/utils.service';
declare var $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  animations: [fuseAnimations],
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faqs: Faqs[] = [];
  faq_menu: any;
  acceuil_menu: any;
  faq_not_found: TypeElements;
  faqsHTML = '';
  search = new FormControl();

  @ViewChild('accordion')
  set watch(accordion: ElementRef) {
    if (accordion) {
      this.initFaq();
    }
  }



  constructor(public userSession: UserSession,
    public route: ActivatedRoute,
    public utils_service: UtilsService,
    public bd_service: BdService) { }

  ngOnInit(): void {
    //trigger get data to actuialise th data in background
    //this.bd_service.get_data();
    //but continue with availabe data
    if (this.bd_service.public_types_elements.length == 0 || this.bd_service.public_elements.length == 0) {
      this.bd_service.public_observable.subscribe(data => {
        //console.log('data updated !');
        this.prepare_data();
      });
    } else {
      this.prepare_data();
    }

    this.bd_service.getArrayData(
      this.bd_service.get_faqs(),
      (data_array: any[], message?: any, success?: boolean) => {
        //faqs
        console.log('data_array', data_array);

        this.faqs = data_array.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        this.generateFaqs(this.faqs);
      }
    );

    this.search.valueChanges.subscribe(value => {
      this.generateFaqs(
        this.faqs.filter(
          faq =>
            this.bd_service.convertToSlug(faq.question.toLocaleLowerCase()).includes(this.bd_service.convertToSlug(value.toLocaleLowerCase()))
            ||
            this.bd_service.convertToSlug(faq.response.toLocaleLowerCase()).includes(this.bd_service.convertToSlug(value.toLocaleLowerCase()))
        )
      )
    });


  }

  prepare_data() {

    const menus = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.ELEMENTS_DU_MENU, this.bd_service.public_elements);
    this.faq_menu = menus.find(m => m.contenu?.menu_item_element_page_link_link_obj?.slug == "faq");
    this.acceuil_menu = menus.find(m => m.contenu?.menu_item_element_page_link_link_obj?.slug == "acceuil");
    //console.log('nos_services_menu', this.nos_services_menu);

    this.faq_not_found = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.FAQ_SECTION_QUESTION_NON_TROUVEE, this.bd_service.public_types_elements);
  }

  generateFaqs(faqs: Faqs[]) {
    console.log('generating', faqs);

    this.faqsHTML = "";
    setTimeout(() => {

      faqs.forEach(faq => {
        this.faqsHTML +=
          `<h3 class="title bold medium cursor-pointer">${faq.question}</h3>
        <div>
          <p class="position-relative">
            <!-- floating logo -->
            <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="particlemove1 floating-logo2 position-absolute">
            ${faq.response}
          </p>
        </div>
        `;
      });
      this.initFaq();
    }, 100);
  }

  initFaq() {
    setTimeout(() => {

      var icons = {
        header: "ui-icon-circle-plus",
        activeHeader: "ui-icon-circle-minus",
      };
      $("#accordion").accordion({
        icons: icons,
        heightStyle: "content",
      });
      $("#toggle")
        .button()
        .on("click", function () {
          if ($("#accordion").accordion("option", "icons")) {
            $("#accordion").accordion("option", "icons", null);
          } else {
            $("#accordion").accordion("option", "icons", icons);
          }
        });
    }, 0);
  }

}
