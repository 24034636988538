import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '../../../components/shared/animations';
import { Elements, TypeElements } from '../../../interfaces';
import { BdService } from '../../../services/bd.service';
import { UserSession } from '../../../services/user-session.service';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  animations: [fuseAnimations],
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contact_menu: Elements;
  acceuil_menu: Elements;
  contact_form_type: TypeElements;
  contact_coords_type: TypeElements;
  contact_form: FormGroup;

  constructor(public userSession: UserSession,
    public route: ActivatedRoute,
    public form_builder: FormBuilder,
    public utils_service: UtilsService,
    public bd_service: BdService) { }


  ngOnInit(): void {
    //trigger get data to actuialise th data in background
    //this.bd_service.get_data();
    //but continue with availabe data
    if (this.bd_service.public_types_elements.length == 0 || this.bd_service.public_elements.length == 0) {
      this.bd_service.public_observable.subscribe(data => {
        //console.log('data updated !');
        this.prepare_data();
      });
    } else {
      this.prepare_data();
    }

    this.contact_form = this.form_builder.group({
      'name': ['', Validators.required],
      'firsname': ['', Validators.required],
      'mail': ['', Validators.required],
      'phone': ['', Validators.required],
      'message': ['', Validators.required]
    });


  }

  prepare_data() {

    const menus = this.userSession.filter_elements_by_type(this.bd_service.TYPES_MAP.ELEMENTS_DU_MENU, this.bd_service.public_elements);
    this.contact_menu = menus.find(m => m.contenu?.menu_item_element_page_link_link_obj?.slug == "contact");
    this.acceuil_menu = menus.find(m => m.contenu?.menu_item_element_page_link_link_obj?.slug == "acceuil");
    //console.log('nos_services_menu', this.nos_services_menu);

    this.contact_form_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.CONTACT_FORMULAIRE_DE_CONTACT, this.bd_service.public_types_elements);
    this.contact_coords_type = this.userSession.find_type_elements_by_slug(this.bd_service.TYPES_MAP.CONTACT_COORDONNEES, this.bd_service.public_types_elements);

  }

  send_message() {

  }
}
