import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appRemoveOnNull]'
})
export class RemoveOnNullDirective {
  el: HTMLElement;

  constructor(elRef: ElementRef) {
    this.el = elRef.nativeElement;
  }

  @Input() set appRemoveOnNull(value_to_test: any) {
    if (value_to_test == undefined || value_to_test == null || value_to_test == '') {
      this.el.classList.add('d-none');
      this.el.setAttribute("removed-on-null", "true");
    } else {
      this.el.setAttribute("removed-on-null", "false");
      this.el.classList.remove('d-none');
    }
  }
}
