<app-header></app-header>
<!-- hero section -->
<section [@slideInLeft] [@slideInRight] class="d-flex flex-column align-items-center w-100 mt-4 mb-3" *ngIf="qui_sommes_nous_menu != undefined">
  <div class="section-content d-flex flex-column align-items-center hero-qsmn position-relative mt-4">
    <div class="breadcrumbs d-flex flex-column align-items-center mb-3">
      <h1 class="gray2 xlarge center title bold mb-1">{{qui_sommes_nous_menu != undefined ? qui_sommes_nous_menu.contenu.menu_item_element_title : 'Qui sommes-nous'}}</h1>
      <p class="title small center bold"><a [routerLink]="['/']" class="primary hover-to-accent">{{acceuil_menu != undefined ? acceuil_menu.contenu.menu_item_element_short_title : 'Accueil'}}</a> • <span class="gray2 light">{{qui_sommes_nous_menu != undefined ? qui_sommes_nous_menu.contenu.menu_item_element_short_title : 'Qui sommes-nous'}}</span></p>
    </div>
    <!-- will be owl carousel after js loading -->
    <div class="qsmn-present d-flex flex-column align-items-center w-100 qsmn-back" *ngIf="qui_sommes_nous_hero != undefined">
      <img [src]="qui_sommes_nous_hero.contenu.about_us_landing_logo?.path | imgUrl" [appRemoveOnNull]="qui_sommes_nous_hero.contenu.about_us_landing_logo" alt="" class="big-logo mb-2">
      <h1 class="black medium bold center" [appRemoveOnNull]="qui_sommes_nous_hero.contenu.about_us_landing_slogan">{{qui_sommes_nous_hero.contenu.about_us_landing_slogan}}</h1>
      <!-- resume -->
      <div class="resume header-shadow white-back d-flex flex-column flex-lg-row align-items-center justify-items-center w-80 py-4 px-5 " *ngIf="service_une_phrase_type != undefined">
        <!-- feature -->
        <p class="gray2 left feature mt-4 mx-5" [appRemoveOnNull]="service.contenu.one_phrase_service_element_title" *ngFor="let service of service_une_phrase_elements">
          <span class="title bold medium mb-2">{{service.contenu.one_phrase_service_element_title}}</span> <br>
          <span class="small regular" [innerHTML]="service.contenu.one_phrase_service_element_content | sanitizeHtml"></span>
        </p>
      </div>
    </div>

  </div>
</section>

<!-- qui sommes nous section -->
<section [@slideInLeft] [@slideInRight] class="qui-sommes-nous d-flex flex-row align-items-center justify-content-center w-100 mt-7 mb-7" *ngIf="qui_sommes_nous_type != undefined">
  <div class="section-content d-flex flex-column-reverse flex-lg-row align-items-stretch justify-content-center">
    <!-- illustrations -->
    <div class="illustrations position-relative w-40 mr-4">
      <img [src]="qui_sommes_nous_type.contenu.about_us_back_image?.path | imgUrl" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_back_image" alt="" class="w-80 position-absolute top-0 particlemove2">
      <img [src]="qui_sommes_nous_type.contenu.about_us_front_image?.path | imgUrl" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_front_image" alt="" class="w-80 position-absolute bottom-0 particlemove1">
    </div>
    <!-- texts
      -->
    <div class="texts d-flex flex-column align-items-start w-40 ml-5">
      <p class="primary medium bold left mb-1" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_accent_title">{{qui_sommes_nous_type.contenu.about_us_accent_title}}</p>
      <p class="title font-3-em bold gray2 left mb-2" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_big_title">{{qui_sommes_nous_type.contenu.about_us_big_title}}</p>
      <p class="gray2 medium light left mt-4 qui_sommes_nous_content" [innerHTML]="qui_sommes_nous_type.contenu.about_us_content | sanitizeHtml" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_content"></p>

      <!-- Prise en charge -->
      <div class="d-flex flex-column align-items-start mt-4">
        <p class="gray2 medium bold" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_extra_enum_title">{{qui_sommes_nous_type.contenu.about_us_extra_enum_title}}</p>
        <div class="d-flex flex-row align-items-center mt-1 wrap">
          <!-- Médicale -->
          <p class="d-flex flex-row align-items-center mr-4" [appRemoveOnNull]="qui_sommes_nous_type.contenu.about_us_extra_enum_content" *ngFor="let extra of qui_sommes_nous_type.contenu.about_us_extra_enum_content">
            <img src="../../../../assets/images/SVG/check-green.svg" alt="" class="h-2em mr-2">
            <span class="small regular">{{extra}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- stats section -->
<section [@slideInLeft] [@slideInRight] class="stats-section d-flex flex-row align-items-center justify-content-center w-100 mb-0 mt-7 stats-back p-4" *ngIf="stats_type != undefined">
  <div class="section-content d-flex flex-column flex-lg-row justify-content-around align-items-center">
    <!-- stat-->
    <div class="stat d-flex flex-column align-items-start" *ngFor="let stat of stats_elements; let l = last">
      {{ l === true ? initStatCount() : null}}
      <img [src]="stat.contenu.stat_counter_element_icon?.path | imgUrl" [appRemoveOnNull]="stat.contenu.stat_counter_element_icon" alt="" class="h-3em">
      <!-- counter -->
      <p class="stats-digits title bold font-45-em white left mb-0 mt-3" [appRemoveOnNull]="stat.contenu.stat_counter_element_value">
        {{stat.contenu.stat_counter_element_prefix}}
        <span [attr.delay]="statsCounterDelay" [attr.data-num]="stat.contenu.stat_counter_element_value" class="countfect annees-counter"></span>
        {{stat.contenu.stat_counter_element_suffix}}
      </p>
      <p class="small bold white left" [appRemoveOnNull]="stat.contenu.stat_counter_element_title">{{stat.contenu.stat_counter_element_title}}</p>
    </div>

  </div>

</section>


<!-- partenaires section
    partners_accent_title
    partners_big_title
    partners_content
    partners_accent_button_text
    partners_accent_button_link
    -->
<section [@slideInLeft] [@slideInRight] class="partenaires-section d-flex flex-column align-items-center w-100 mt-0 hero-back mb-0 p-5" *ngIf="partners_type != undefined">
  <div class="section-content d-flex partenaires-carousel-container flex-column justify-content-center align-items-center mt-7 mb-7">
    <!-- texts -->
    <div class="texts d-flex flex-column align-items-center w-40">
      <p class="primary medium bold center mb-1" [appRemoveOnNull]="partners_type.contenu.partners_accent_title">{{partners_type.contenu.partners_accent_title}}</p>
      <p class="title font-3-em bold gray2 center mb-2" [appRemoveOnNull]="partners_type.contenu.partners_big_title">{{partners_type.contenu.partners_big_title}}</p>
      <p class="gray2 medium light center mt-4" [innerHTML]="partners_type.contenu.partners_content | sanitizeHtml" [appRemoveOnNull]="partners_type.contenu.partners_content"></p>
    </div>

    <!-- partner
    partner_element_name
    partner_element_link
    partner_element_logo
      -->
    <div class="d-flex flex-row align-items-center mt-4 owl-carousel">
      <a [appRouterOrHref]="{element: partner, field_key: 'partner_element_link'}" [appRemoveOnNull]="partner.contenu.partner_element_logo" class="h-2-em mx-2" *ngFor="let partner of partners_elements; let l = last">
        {{l === true ? initParnerCarousel() : null}}
        <img [src]="partner.contenu.partner_element_logo?.path | imgUrl" [alt]="'Partenaire Ileira - ' + partner.contenu.partner_element_name" class="h-100">
      </a>
    </div>
    <!-- Découvrez nos Partenaires → -->
    <a [appRouterOrHref]="{element: partners_type, field_key: 'partners_accent_button_link'}" [appRemoveOnNull]="partners_type.contenu.partners_accent_button_text" class="cta cta-primary mr-4 d-flex mt-4">{{partners_type.contenu.partners_accent_button_text}}</a>
  </div>
</section>

<!-- team section
    teams_type
    teams_elements
    teams_accent_title
    teams_big_title
    teams_content

 -->
<section [@slideInLeft] [@slideInRight] class="team-section d-flex flex-column align-items-center w-100 mt-0 white-back p-5" *ngIf="teams_type != undefined">
  <div class="section-content head d-flex flex-column align-items-center team-carousel-container position-relative">
    <!-- floating logo -->
    <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="particlemove2 floating-logo position-absolute">
    <!-- texts -->
    <div class="texts d-flex flex-column align-items-start w-40 mr-auto mb-5">
      <p class="primary medium bold left mb-1" [appRemoveOnNull]="teams_type.contenu.teams_accent_title">{{teams_type.contenu.teams_accent_title}}</p>
      <p class="title font-3-em bold gray2 left mb-2" [appRemoveOnNull]="teams_type.contenu.teams_big_title">{{teams_type.contenu.teams_big_title}}</p>
      <p class="gray2 medium light left mt-4" [innerHTML]="teams_type.contenu.teams_content | sanitizeHtml" [appRemoveOnNull]="teams_type.contenu.teams_content"></p>

    </div>
    <!-- team -->
    <div class="features d-flex flex-column align-items-stretch owl-carousel owl-theme position-relative mt2">
      <!-- team member

        teams_member_name
        teams_member_fuction
        teams_member_image
        teams_member_socials_1_icon
        teams_member_socials_1_link
        teams_member_socials_2_icon
        teams_member_socials_2_link
        teams_member_socials_3_icon
        teams_member_socials_3_link
        teams_member_element_priority
       -->
      <div class="feature white-back features-shadow d-flex flex-column align-items-center px-4 pt-0 pb-4 mx-2" *ngFor="let member of teams_elements; let l = last;">
        {{l === true ? initTeamCarousel() : null}}
        <img [src]="member.contenu.teams_member_image?.path | imgUrl" [appRemoveOnNull]="member.contenu.teams_member_image" style="height: 15rem; object-fit: cover;" [alt]="'Ileira membre - ' + member.contenu.teams_member_name" class="feature-ill mb-2 w-95">
        <p class="primary title bold medium center mb-0" [appRemoveOnNull]="member.contenu.teams_member_name">{{member.contenu.teams_member_name}}</p>
        <p class="small regular gray2 center mb-1" [appRemoveOnNull]="member.contenu.teams_member_fuction">{{member.contenu.teams_member_fuction}}</p>
        <!-- socials -->
        <div class="d-flex flex-row align-items-center">
          <a [appRouterOrHref]="{element: member, field_key: 'teams_member_socials_1_link'}" [appRemoveOnNull]="member.contenu.teams_member_socials_1_link" class="d-flex flex-row align-items-center h-1em mr-2">
            <i [class]="'primary fa fa-' + member.contenu.teams_member_socials_1_icon"></i>
          </a>
          <a [appRouterOrHref]="{element: member, field_key: 'teams_member_socials_2_link'}" [appRemoveOnNull]="member.contenu.teams_member_socials_2_link" class="d-flex flex-row align-items-center h-1em mr-2">
            <i [class]="'primary fa fa-' + member.contenu.teams_member_socials_2_icon"></i>
          </a>
          <a [appRouterOrHref]="{element: member, field_key: 'teams_member_socials_3_link'}" [appRemoveOnNull]="member.contenu.teams_member_socials_3_link" class="d-flex flex-row align-items-center h-1em mr-2">
            <i [class]="'primary fa fa-' + member.contenu.teams_member_socials_3_icon"></i>
          </a>
        </div>
      </div>

    </div>
  </div>
</section>
<app-footer></app-footer>
