<app-header></app-header>
<!-- hero section -->
<section [@slideInLeft] [@slideInRight] class="d-flex flex-column align-items-center w-100 standard-section-back p-5" *ngIf="nos_services_menu != undefined">
  <div class="section-content d-flex flex-column align-items-center position-relative mt-4">
    <div class="breadcrumbs d-flex flex-column align-items-center mb-3">
      <h1 class="gray2 xlarge center title bold mb-1">{{nos_services_menu != undefined ? nos_services_menu.contenu.menu_item_element_title : 'Nos services et prestations'}}</h1>
      <p class="title small center bold"><a [routerLink]="['/']" class="primary hover-to-accent">{{acceuil_menu != undefined ? acceuil_menu.contenu.menu_item_element_short_title : 'Accueil'}}</a> • <span class="gray2 light">{{nos_services_menu != undefined ? nos_services_menu.contenu.menu_item_element_short_title : 'Nos services et prestations'}}</span></p>
    </div>
  </div>
</section>

<!-- services section
      nos_services_type
      nos_services_elements
      our_products_element_content
      -->
<section [@slideInLeft] [@slideInRight] class="services-section d-flex flex-row align-items-center justify-content-center white-back w-100 mt-2 mb-7" *ngIf="nos_services_type != undefined">
  <div class="section-content d-flex flex-column align-items-center justify-content-center">
    <h1 class="main-text gray2 large light my-5 w-70 center" [innerHTML]="nos_services_type.contenu.our_products_content | sanitizeHtml"></h1>

    <div class="services d-flex flex-column align-items-center justify-content-center w-100">
      <!-- service row
    our_products_element_title
    our_products_element_content
    our_products_element_accent_button_text
    our_products_element_accent_button_link
    our_products_element_icon
    our_products_element_image
        -->
      <div class="features service-row d-flex flex-column flex-lg-row align-items-stretch position-relative mt-4 mb-4 position-relative" [ngClass]="{'flex-lg-row-reverse': i%2 != 0}" *ngFor="let service of nos_services_elements; let i = index;">
        <!-- head -->
        <img [src]="service.contenu.our_products_element_image?.path | imgUrl" alt="" class="head">
        <!-- feature -->
        <div class="feature white-back features-shadow d-flex flex-column align-items-center p-4 ">
          <img [src]="service.contenu.our_products_element_icon?.path | imgUrl" [appRemoveOnNull]="service.contenu.our_products_element_icon?.path" alt="" class="feature-ill mb-2">
          <p class="gray2 title bold medium center" [appRemoveOnNull]="service.contenu.our_products_element_content">{{service.contenu.our_products_element_title}}</p>
          <p class="small regular gray2 center mb-2 nos-produits-section-p" [appRemoveOnNull]="service.contenu.our_products_element_content" [innerHTML]="service.contenu.our_products_element_content.length < 250 ? service.contenu.our_products_element_content : service.contenu.our_products_element_content.substr(0, 250) | sanitizeHtml"></p>
          <a [appRouterOrHref]="{element: service, field_key: 'our_products_element_accent_button_link'}" [appRemoveOnNull]="service.contenu.our_products_element_accent_button_text" class="primary hover-to-accent bold mb-3">{{service.contenu.our_products_element_accent_button_text}}</a>
        </div>
        <!-- floating-logo -->
        <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="floating-logo" [ngClass]="{'particlemove1': i%2 != 0 && i%3 != 0, 'particlemove2': i%2 == 0, 'particlemove': i%3 == 0}">
      </div>

    </div>

    <!-- Devenir Membre Maintenant → -->
    <a [appRouterOrHref]="{element: nos_services_type, field_key: 'our_products_accent_button_link'}" [appRemoveOnNull]="nos_services_type.contenu.our_products_accent_button_text" class="cta cta-primary mr-4 d-flex mt-6 mb-3">{{nos_services_type.contenu.our_products_accent_button_text}}</a>
  </div>
</section>
<app-footer></app-footer>
