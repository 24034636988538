<!-- Back to top -->
<a href="#" class="back-to-top" id="back-to-top">
    <img src="../../../../assets/images/SVG/chevron-up-white.svg" alt="">
</a>

<!-- footer
-->
<footer [@slideInBottom] [@slideOutBottom]  class="d-flex flex-column w-100 align-items-center footer-back" *ngIf="footer_type?.contenu != undefined">
    <div class="container flex-column w-100 align-items-center" >
        <!-- before line -->
        <div class="before-line d-flex flex-wrap flex-row align-items-end position-relative" >
            <a class="footer-logo mr-auto" href=""><img src="../../../../assets/images/SVG/logo-white.svg" alt=""></a>
            <div style="width: 100%" class="d-lg-none"></div>
            <a class="footer-socials" [appRemoveOnNull]="footer_type.contenu.footer_socials_1_link" [appRouterOrHref]="{element: footer_type, field_key: 'footer_socials_1_link'}" ><i [class]="'medium white mx-auto fa fa-' + footer_type.contenu.footer_socials_1_icon"></i></a>
            <a class="footer-socials" [appRemoveOnNull]="footer_type.contenu.footer_socials_2_link" [appRouterOrHref]="{element: footer_type, field_key: 'footer_socials_2_link'}" ><i [class]="'medium white mx-auto fa fa-' + footer_type.contenu.footer_socials_2_icon"></i></a>
            <a class="footer-socials" [appRemoveOnNull]="footer_type.contenu.footer_socials_3_link" [appRouterOrHref]="{element: footer_type, field_key: 'footer_socials_3_link'}"><i [class]="'medium white mx-auto fa fa-' + footer_type.contenu.footer_socials_3_icon"></i></a>
        </div>
        <!-- line separator -->
        <div class="line-separator"></div>
        <!-- after libne
      -->
        <div class="after-line d-flex flex-lg-row flex-column align-items-lg-start align-items-center position-relative" >
            <!-- Contactez-nous -->
            <div class="footer-group d-flex flex-column w-100 align-items-start">
                <h3 class="text-white text-lg-left text-center font-weight-bold medium">Contactez-nous</h3>
                <div class="contacts-group d-flex flex-row align-items-start position-relative" [appRemoveOnNull]="footer_type.contenu.footer_contact_element_1_title">
                    <h4 class="text-white text-left font-weight-bold small">
                        <span class="title bold">{{footer_type.contenu.footer_contact_element_1_title}}</span><br>
                        <i [class]="'mr-2 fa fa-' + footer_type.contenu.footer_contact_element_1_icon"></i> <span class="font-weight-light">{{footer_type.contenu.footer_contact_element_1_text}}</span>
                    </h4>
                </div>
                <div class="contacts-group d-flex flex-row align-items-start position-relative" [appRemoveOnNull]="footer_type.contenu.footer_contact_element_2_title">
                    <h4 class="text-white text-left font-weight-bold small">
                        <span class="title bold">{{footer_type.contenu.footer_contact_element_2_title}}</span><br>
                        <i [class]="'mr-2 fa fa-' + footer_type.contenu.footer_contact_element_2_icon"></i> <span class="font-weight-light">{{footer_type.contenu.footer_contact_element_2_text}}</span>
                    </h4>
                </div>
                <div class="contacts-group d-flex flex-row align-items-start position-relative" [appRemoveOnNull]="footer_type.contenu.footer_contact_element_3_title">
                    <h4 class="text-white text-left font-weight-bold small">
                        <span class="title bold">{{footer_type.contenu.footer_contact_element_3_title}} </span><br>
                        <i [class]="'mr-2 fa fa-' + footer_type.contenu.footer_contact_element_3_icon"></i> <span class="font-weight-light">{{footer_type.contenu.footer_contact_element_3_text}}</span>
                    </h4>
                </div>
            </div>
            <!-- Liens Rapides -->
            <div class="footer-group d-flex flex-column w-100 align-items-start">
                <h3 class="text-white text-lg-left text-center font-weight-bold medium">Liens Rapides</h3>
                <ul>
                    <li *ngFor="let menu of elements_du_menu_type.contenu.children"><a class="title bold" [appRemoveOnNull]="menu.contenu.menu_item_element_title" [appRouterOrHref]="{element: menu, field_key: 'menu_item_element_page_link'}">{{menu.contenu.menu_item_element_title}}</a></li>
                </ul>
            </div>
            <!-- Nos Horaires -->
            <div class="footer-group d-flex flex-column w-100 align-items-start">
                <h3 class="text-white text-lg-left text-center font-weight-bold medium">Nos Horaires</h3>
                <p class="text-white text-lg-left text-center small d-flex flex-column align-items-start">
                    <span class="white light" [appRemoveOnNull]="footer_type.contenu.footer_hours_element_1_title">{{footer_type.contenu.footer_hours_element_1_title}}</span>
                    <span class="title bold white mb-2" [appRemoveOnNull]="footer_type.contenu.footer_hours_element_1_title">{{footer_type.contenu.footer_hours_element_1_text}}</span>
                    <span class="white light" [appRemoveOnNull]="footer_type.contenu.footer_hours_element_2_title">{{footer_type.contenu.footer_hours_element_2_title}}</span>
                    <span class="title bold white mb-2" [appRemoveOnNull]="footer_type.contenu.footer_hours_element_2_title">{{footer_type.contenu.footer_hours_element_2_text}}</span>
                    <span class="white light" [appRemoveOnNull]="footer_type.contenu.footer_hours_element_3_title">{{footer_type.contenu.footer_hours_element_3_title}}</span>
                    <span class="title bold white mb-2" [appRemoveOnNull]="footer_type.contenu.footer_hours_element_3_title">{{footer_type.contenu.footer_hours_element_3_text}}</span>
                </p>

            </div>
            <!-- Newsletter -->
            <div class="footer-group d-flex flex-column w-100 align-items-lg-start align-items-center" >
                <h3 class="text-white text-lg-left text-center font-weight-bold medium" [appRemoveOnNull]="footer_type.contenu.footer_newsletter_title">{{footer_type.contenu.footer_newsletter_title}}</h3>
                <p class="text-white text-lg-left text-center small d-flex flex-column align-items-start" [appRemoveOnNull]="footer_type.contenu.footer_newsletter_title">
                    {{footer_type.contenu.footer_newsletter_text}}
                </p>
                <form class="newsletter-input d-flex flex-row align-items-center position-relative white-back">
                    <input type="email" placeholder="Votre adresse mail" name="email" id="">
                    <button type="submit" class="cta cta-primary">→</button>
                </form>
            </div>

        </div>
        <p class="copyright text-white text-center ml-auto mr-auto font-weight-light medium " [appRemoveOnNull]="footer_type.contenu.footer_copyright_text">{{footer_type.contenu.footer_copyright_text}}</p>

    </div>

</footer>
