import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiUrlPipe } from './api-url.pipe';
import { AppUrlPipe } from './app-url.pipe';
import { ImgUrlPipe } from './img-url.pipe';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { ServerCodesPipe } from './server-codes.pipe';
import { MontantifyPipe } from './montantify.pipe';
import { FourNumberifyPipe } from './four-numberify.pipe';


@NgModule({
  declarations: [
    ApiUrlPipe,
    AppUrlPipe,
    ImgUrlPipe,
    SanitizeHtmlPipe,
    ServerCodesPipe,
    MontantifyPipe,
    FourNumberifyPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ApiUrlPipe,
    AppUrlPipe,
    ImgUrlPipe,
    SanitizeHtmlPipe,
    ServerCodesPipe,
    MontantifyPipe,
    FourNumberifyPipe,
  ]
})
export class PipeModule { }
