import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { ShowingAlert } from '../../../interfaces';
import { fuseAnimations } from '../animations';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  animations: [fuseAnimations],
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, AfterViewChecked {
  alert_type: "success" | "info" | "warning" | "danger";
  alert_icon: "check" | "info-circle" | "warning" | "times-circle";
  alert_title: string = "";
  alert_message: string = "";
  alert_id: string = "";
  @Input() alert: ShowingAlert;
  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
    this.alert_icon = this.alert?.alert_icon;
    this.alert_type = this.alert?.alert_type;
    this.alert_title = this.alert?.alert_title;
    this.alert_message = this.alert?.alert_message;
    this.alert_id = this.alert?.showing_alert_id;
  }
  close(){
    this.alert = undefined;
  }

}
