<app-header></app-header>
<!-- hero section -->
<section [@slideInLeft] [@slideInRight]  class="d-flex flex-column align-items-center w-100 standard-section-back p-5">
  <div class="section-content d-flex flex-column align-items-center position-relative mt-4">
    <div class="breadcrumbs d-flex flex-column align-items-center mb-3">
    <h1 class="gray2 xlarge center title bold mb-1">{{contact_menu != undefined ? contact_menu.contenu.menu_item_element_title : 'Contact'}}</h1>
      <p class="title small center bold"><a [routerLink]="['/']" class="primary hover-to-accent">{{acceuil_menu != undefined ? acceuil_menu.contenu.menu_item_element_short_title : 'Accueil'}}</a> • <span class="gray2 light">{{contact_menu != undefined ? contact_menu.contenu.menu_item_element_short_title : 'Contac'}}</span></p>
    </div>
  </div>
</section>

<!-- contact section

  contact_form_type
  contact_coords_type

  contact_form_title
  contact_form_content
  contact_form_button_text
  contact_form_button_link
  contact_form_name_placeholder
  contact_form_firsname_placeholder
  contact_form_mail_placeholder
  contact_form_phone_placeholder
  contact_form_message_placeholder
  contact_form_send_text
  -->
<section [@slideInLeft] [@slideInRight] class="contact-section d-flex flex-row align-items-center justify-content-center white-back w-100 pt-5 pb-3" *ngIf="contact_form_type != undefined">

  <div class="w-80 section-content d-flex flex-column flex-lg-row align-items-start justify-content-center position-relative mt-5 mb-7">
    <!-- floating logo -->
    <img src="../../../../assets/images/SVG/logo-mark.svg" alt="" class="particlemove2 floating-logo2 position-absolute left-0 z-index--1">
    <!-- texts -->
    <div class="texts d-flex flex-column align-items-center align-items-lg-start mr-0 mr-lg-5">
      <p class="title xlarge bold gray2 text-lg-left text-center" [appRemoveOnNull]="contact_form_type.contenu.contact_form_title">{{contact_form_type.contenu.contact_form_title}}</p>
      <p class="large light gray2 text-lg-left text-center px-3 px-lg-0 mt-5 mb-5" [appRemoveOnNull]="contact_form_type.contenu.contact_form_content" [innerHTML]="contact_form_type.contenu.contact_form_content | sanitizeHtml"></p>
      <a [appRouterOrHref]="{element: contact_form_type, field_key: 'contact_form_button_link'}" [appRemoveOnNull]="contact_form_type.contenu.contact_form_button_text" class="primary left hover-to-accent bold mt-2 d-none d-lg-block">{{contact_form_type.contenu.contact_form_button_text}}</a>
    </div>
    <!-- form -->
    <form class="d-flex flex-row flex-wrap align-items-center position-relative justify-content-center" [formGroup]="contact_form">
      <!-- Votre nom -->
      <div class="newsletter-input newsletter-input-no-cta d-flex flex-row align-items-center white-back border m-2 gray5-border">
        <i class="fa fa-user mr-2"></i>
        <input type="text" [placeholder]="contact_form_type.contenu.contact_form_name_placeholder" formControlName="name" class="my-auto">
      </div>
      <!-- Votre prénom -->
      <div class="newsletter-input newsletter-input-no-cta d-flex flex-row align-items-center white-back border m-2 gray5-border">
        <i class="fa fa-user mr-2"></i>
        <input type="text" [placeholder]="contact_form_type.contenu.contact_form_firsname_placeholder" formControlName="firsname" class="my-auto">
      </div>
      <div class="flex-br"></div>
      <!-- Votre adresse courriel -->
      <div class="newsletter-input newsletter-input-no-cta d-flex flex-row align-items-center white-back border m-2 gray5-border">
        <i class="fa fa-envelope mr-2"></i>
        <input type="email" [placeholder]="contact_form_type.contenu.contact_form_mail_placeholder"  formControlName="mail" class="my-auto">
      </div>
      <!-- Votre adresse téléphonique -->
      <div class="newsletter-input newsletter-input-no-cta d-flex position-relative flex-column align-items-center white-back border m-2 gray5-border">
        <input type="tel" [placeholder]="contact_form_type.contenu.contact_form_phone_placeholder"  formControlName="phone" class="intl-tel-input my-auto">
        <span id="" class="valid-msg">✓ Valide</span>
        <span id="" class="error-msg"></span>
      </div>
      <div class="flex-br"></div>
      <!-- Votre message -->
      <div class="newsletter-input newsletter-textarea newsletter-input-no-cta  w-100 max-w-100 d-flex flex-row align-items-center white-back border m-2 gray5-border ">
        <textarea type="tel" [placeholder]="contact_form_type.contenu.contact_form_message_placeholder" formControlName="message" rows="10" class="pt-3 w-100 my-auto"></textarea>
      </div>
      <div class="flex-br"></div>
      <button type="submit" class="cta cta-primary primary-border border border-1 mt-3 justify-content-center" (click)="send_message()">{{contact_form_type.contenu.contact_form_send_text}}</button>
    </form>

  </div>
</section>

<!-- coords section -->
<section [@slideInLeft] [@slideInRight]  class="coords-section d-flex flex-row align-items-center justify-content-center justify-content-lg-end w-100" *ngIf="contact_coords_type != undefined">

  <div class="w-80 section-content d-flex flex-column flex-lg-row align-items-center ml-lg-auto ml-0 justify-content-center my-5 my-lg-0 justify-content-lg-end position-relative">

    <!-- coords
    contact_coords_type

    contact_coords_title
    contact_coords_content
    contact_coords_element_1_title
    contact_coords_element_1_text
    contact_coords_element_1_icon
    contact_coords_element_2_title
    contact_coords_element_2_text
    contact_coords_element_2_icon
    contact_coords_element_3_title
    contact_coords_element_3_text
    contact_coords_element_3_icon
    contact_coords_map_content
      -->
    <div class="coords d-flex flex-column align-items-lg-start align-items-center mr-0 mr-lg-7 mb-5 mb-lg-0">
      <p class="title large bold gray2 text-center text-lg-left mb-0">{{contact_coords_type.contenu.contact_coords_title}}</p>
      <p class="small regular gray2 text-center text-lg-left mb-3" [innerHTML]="contact_coords_type.contenu.contact_coords_content | sanitizeHtml"></p>
      <div class="features d-flex flex-column align-items-stretch position-relative mt2">
        <!-- Téléphone -->
        <div class="feature white-back features-shadow d-flex flex-column align-items-center px-5 py-4 my-2" [appRemoveOnNull]="contact_coords_type.contenu.contact_coords_element_1_title">
          <i [class]="'fa fa-' + contact_coords_type.contenu.contact_coords_element_1_icon"></i>
          <p class="small bold gray2 center mb-2">{{contact_coords_type.contenu.contact_coords_element_1_title}}</p>
          <p class="small light gray2 center mb-2">{{contact_coords_type.contenu.contact_coords_element_1_text}}</p>
        </div>
        <!-- E-mail -->
        <div class="feature white-back features-shadow d-flex flex-column align-items-center px-5 py-4 my-2" [appRemoveOnNull]="contact_coords_type.contenu.contact_coords_element_2_title">
          <i [class]="'fa fa-' + contact_coords_type.contenu.contact_coords_element_2_icon"></i>
          <p class="small bold gray2 center mb-2">{{contact_coords_type.contenu.contact_coords_element_2_title}}</p>
          <p class="small light gray2 center mb-2">{{contact_coords_type.contenu.contact_coords_element_2_text}}</p>
        </div>
        <!-- Adresse -->
        <div class="feature white-back features-shadow d-flex flex-column align-items-center px-5 py-4 my-2" [appRemoveOnNull]="contact_coords_type.contenu.contact_coords_element_3_title">
          <i [class]="'fa fa-' + contact_coords_type.contenu.contact_coords_element_3_icon"></i>
          <p class="small bold gray2 center mb-2">{{contact_coords_type.contenu.contact_coords_element_3_title}}</p>
          <p class="small light gray2 center mb-2">{{contact_coords_type.contenu.contact_coords_element_3_text}}</p>
        </div>
      </div>

    </div>

    <!-- map -->
    <div class="w-70 ml-4" [innerHTML]="contact_coords_type.contenu.contact_coords_map_content | sanitizeHtml" >
    </div>


  </div>
</section>
<app-footer></app-footer>
