import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fourNumberify'
})
export class FourNumberifyPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    let num: string = value + "";
    if (num.length == 1) {
      return "000" + num;
    } else if (num.length == 2) {
      return "00" + num;
    } else if (num.length == 3) {
      return "0" + num;
    } else {
      return num;
    }
  }

}
