import { Injectable } from '@angular/core';

@Injectable()
export class FileFactoryService {
  saveTextAsFile(data: string, filename: string, typeMine: string = 'text/plain') {
    if (!data) {
      // console.error('Console.save: No data');
      return;
    }

    const blob = new Blob([data], {type: typeMine});
    let e    = document.createEvent('MouseEvents');
    let a    = document.createElement('a');

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
       e = document.createEvent('MouseEvents');
       a = document.createElement('a');

       a['download'] = filename;
       a['href'] = window.URL['createObjectURL'](blob);
       a.dataset.downloadurl = [typeMine, a['download'],  a['href']].join(':');
       e.initEvent('click', true, false);
       a.dispatchEvent(e);
    }
  }
}
