/* eslint-disable @typescript-eslint/naming-convention */
import { Pipe, PipeTransform } from '@angular/core';
import { BdService } from '../services/bd.service';

@Pipe({
  name: 'apiUrl',
})
export class ApiUrlPipe implements PipeTransform {
  constructor(public bd_service: BdService) {}

  transform(url: string, ...args: unknown[]): string {
    if(url == undefined) return ""; 
    const full_url = this.bd_service.API_SERVER + url;
    return url.includes('data:') ? url : full_url;
  }
}
