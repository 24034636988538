import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemoveOnNullDirective } from './remove-on-null.directive';
import { RouterOrHrefDirective } from './router-or-href.directive';
import { TransitionGroupItemDirective } from './transition-group-item.directive';



@NgModule({
  declarations: [
    TransitionGroupItemDirective,
    RemoveOnNullDirective,
    RouterOrHrefDirective,
  ],
  exports: [
    TransitionGroupItemDirective,
    RemoveOnNullDirective,
    RouterOrHrefDirective,
  ],
  imports: [
    CommonModule
  ]
})
export class DirectiveModule { }
