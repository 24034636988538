<app-header></app-header>
<!-- hero section -->
<section [@slideInLeft] [@slideInRight] class="d-flex flex-column align-items-center w-100 standard-section-back p-5" *ngIf="faq_menu != undefined">
  <div class="section-content d-flex flex-column align-items-center position-relative mt-4">
    <div class="breadcrumbs d-flex flex-column align-items-center mb-3">
      <h1 class="gray2 xlarge center title bold mb-1">{{faq_menu != undefined ? faq_menu.contenu.menu_item_element_title : 'FAQ'}}</h1>
      <p class="title small center bold"><a [routerLink]="['/']" class="primary hover-to-accent">{{acceuil_menu != undefined ? acceuil_menu.contenu.menu_item_element_short_title : 'Accueil'}}</a> • <span class="gray2 light">{{faq_menu != undefined ? faq_menu.contenu.menu_item_element_short_title : 'FAQ'}}</span></p>
    </div>
  </div>
</section>

<!-- faq section -->
<section [@slideInLeft] [@slideInRight] class="faq-section d-flex flex-row align-items-center justify-content-center white-back w-100 mt-2 mb-7" *ngIf="faq_menu != undefined">

  <div class="section-content d-flex flex-column align-items-center ">

    <h1 class="main-text gray2 large light center mt-5 mb-3">
      Une question ? <br>
      Nous l'avons peut-être déjà devinée.</h1>

    <form class="newsletter-input d-flex flex-row align-items-center position-relative white-back border mb-5 gray5-border">
      <input type="text" placeholder="Tapez un terme " [formControl]="search" id="">
      <button type="submit" class="cta cta-primary">→</button>
    </form>

    <div id="accordion" #accordion class="mb-7 " *ngIf="faqsHTML != ''" [innerHTML]="faqsHTML | sanitizeHtml" >
    </div>
    <p class="gray3 center w-50 large bold mb-5" *ngIf="faqsHTML == ''">Pas de résultats à afficher !</p>
  </div>
</section>

<!-- not found section
    faq_not_found_element_title
    faq_not_found_element_sub_title
    faq_not_found_element_accent_button_text
    faq_not_found_element_accent_button_link
    faq_not_found_element_image_1
    faq_not_found_element_image_2
    faq_not_found_element_image_3
    -->
<section [@slideInLeft] [@slideInRight] class="not-found-section d-flex flex-column align-items-center justify-content-center w-100 mt-2 mb-7" *ngIf="faq_not_found != undefined">
  <div class="d-flex flex-column align-items-center not-found-content">
    <!-- profiles -->
    <div class="d-flex flex-row align-items-end profiles mb-3">
      <img [src]="faq_not_found.contenu.faq_not_found_element_image_1?.path | imgUrl" [appRemoveOnNull]="faq_not_found.contenu.faq_not_found_element_image_1" alt="" class="profile rounded-full white-border border border-4 mb-0 h-4em">
      <img [src]="faq_not_found.contenu.faq_not_found_element_image_2?.path | imgUrl" [appRemoveOnNull]="faq_not_found.contenu.faq_not_found_element_image_2" alt="" class="profile rounded-full white-border border border-4 mb-0 h-5em active">
      <img [src]="faq_not_found.contenu.faq_not_found_element_image_3?.path | imgUrl" [appRemoveOnNull]="faq_not_found.contenu.faq_not_found_element_image_3" alt="" class="profile rounded-full white-border border border-4 mb-0 h-4em">
    </div>
    <p class="gray2 medium bold center" [appRemoveOnNull]="faq_not_found.contenu.faq_not_found_element_title">
      {{faq_not_found.contenu.faq_not_found_element_title}}
    </p>
    <p class="gray2 large light center" [appRemoveOnNull]="faq_not_found.contenu.faq_not_found_element_sub_title">
      {{faq_not_found.contenu.faq_not_found_element_sub_title}}
    </p>
    <!-- Contactez nous → -->
    <a [appRouterOrHref]="{element: faq_not_found, field_key: 'faq_not_found_element_accent_button_link'}" [appRemoveOnNull]="faq_not_found.contenu.faq_not_found_element_accent_button_text" class="cta cta-primary mr-4 d-flex mt-1 mb-3">{{faq_not_found.contenu.faq_not_found_element_accent_button_text}}</a>
  </div>

</section>
<app-footer></app-footer>
