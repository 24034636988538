/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable curly */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable arrow-parens */

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Articles } from '../../../interfaces';
import { BdService } from '../../../services/bd.service';
import { UserSession } from '../../../services/user-session.service';
import { UtilsService } from '../../../services/utils.service';
import { fuseAnimations} from '../../../components/shared/animations';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  animations: [fuseAnimations],
styleUrls: ['./articles-list.component.css']
})
export class ArticlesListComponent implements OnInit {
  @Input() articles: Articles[] = [];
  @Input() simpleDisplayIds: number[] = [];
  @Input() read_more: string;

  constructor(
    public userSession: UserSession,
    public route: ActivatedRoute,
    public utils_service: UtilsService,
    public bd_service: BdService
  ) { }

  ngOnInit(): void {
    //console.log('artices', this.articles);

  }
  setArticlesPopulated() {

    //console.log('artices', this.articles);
  }
  get_article_exerpt(article){
    return article.contenu.replace(/<[^>]*>/g, '').substr(0, 100) + '...';
  }

}
